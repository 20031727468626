// const API_URL = "http://localhost:8000/"
// const API_URL= 'http://192.168.1.15:8000/'

const API_URL='https://crm.proptrail.au/'
const CATEGORIES = [];
const themesetting = {};
const Image_Base_Url='https://crm.proptrail.au/uploads/'
const default_img='/img/default_img.png'
export default { API_URL, CATEGORIES, themesetting, Image_Base_Url , default_img};


