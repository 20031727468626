import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../component/Services/apiservices";
import SweetAlert from "react-bootstrap-sweetalert";
import DataContext from "../../component/Elements/context";
const Accountsidebar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const contextValues = useContext(DataContext)
  const [UserData, setUserData] = useState({});
  const [isloading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false);

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getUserdatadetail()
    }
    didMountRef.current = false;
  }, [])
  const logOut = () => {
    setShow(true);
  };

  const Confirm = () => {
    localStorage.removeItem("USER_TOKEN")
    navigate("/");
    window.location.reload()
  };

  const Cancelalert = () => {
    setShow(false);
  };



  const getUserdatadetail = () => {
    setIsLoading(true)
    ApiService.fetchData("/user-detail").then((res) => {
      if (res?.status == "success") {
        setUserData(res?.userData);
        setIsLoading(false)
      }
      else if (res.status=='error' && (res.message == 'session_expired'|| res.message=='Account Inactive')) {
        localStorage.removeItem('USER_TOKEN')
        window.location.href = '/'
        setIsLoading(false)
      }
    
      else{
        setIsLoading(false) 
      }
    }).catch((error) => { })
  };

  return (
    <>
      <div className="account-sidebar">
        <div className="profilebox mb-15">
          <div className="profileboxMedia">
            <img src="/img/profile.png" />
          </div>
          <div className="profileboxContent">
            <h6 className="mb-0">Hi! {UserData.user_name?.toUpperCase() }</h6>
            <p className="mb-0 tx-14">{UserData?.user_email}</p>
          </div>
        </div>
        <div className="aclist mb-3">
          <ul>
          <li className={location?.pathname == "/my-account" ? "active" : ""}>
              <a href="/my-account">
                Account Overview<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            <li className={location?.pathname == "/dashboard" ? "active" : ""}>
              <a href="/dashboard">
                Dashboard<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            <li className={location?.pathname == "/my-profile" ? "active" : ""}>
              <a href="/my-profile">
                My Profile<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            <li className={location?.pathname == "/my-strategies" ? "active" : ""}>
              <a href="/my-strategies">
                My Strategy<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            <li className={location?.pathname == "/my-property" ? "active" : ""}>
              <a href="/my-property">
                My Properties<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            <li className={location?.pathname == "/help-support" ? "active" : ""}>
              <a href="/help-support">
               Help & Support<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
           
          </ul>
        </div>
        <div className="aclist">
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                className="pb-0 pt-0"
                onClick={() => {
                  logOut();
                }}
              >
                Logout<i className="d-icon-angle-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <SweetAlert
        warning
        confirmBtnCssClass="alertpop"
        title={` Are you sure you want to Logout ? `}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        show={show}
        onConfirm={Confirm}
        onCancel={Cancelalert}
        btnSize="md"
        showCancel
        cancelBtnBsStyle="danger"
      ></SweetAlert>

    </>
  );
};

export default Accountsidebar;
