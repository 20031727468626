import React, { useState, useEffect, useRef, useContext } from "react";
import Loginmodal from "../modals/login_modal";
import { useNavigate } from "react-router-dom";
import DataContext from "../Elements/context";
import { ApiService } from "../Services/apiservices";
const Header = () => {
    let getusertoken = localStorage.getItem("USER_TOKEN")
    const contextValues = useContext(DataContext)
    const [showlogin, setshowlogin] = useState(false)
    const [type, settype] = useState('')
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const handleClose = () => {
        setshowlogin(false)
    }
    const handlelogin = () => {
        if (getusertoken == null) {
            setshowlogin(true)
        }
        else {

        }
    }
    useEffect(() => {
        if (didMountRef.current) {
            if (getusertoken) {
                getUserdatadetail()
                getCurrency()
            }

        }
        didMountRef.current = false;
    }, [])
    const getUserdatadetail = () => {
        ApiService.fetchData("/user-detail").then((res) => {
            if (res?.status == "success") {
                contextValues.setRowUserData(res?.userData);
            }
            else if (res.status == 'error' && (res.message == 'session_expired' || res.message == 'Account Inactive')) {
                localStorage.removeItem('USER_TOKEN')
                window.location.href = '/'

            }
        }).catch((error) => { })
    }


    const getCurrency=()=>{
        ApiService.fetchData("/admin/dashboard/getcurrency").then((res) => {
            if (res?.status == "success") {
                contextValues.setCurrency((res?.data)[0]?.currencyCode ,'currencyCode');
            } 
        }).catch((error) => { }) 
    }

  
    return (<>
        <header className="header">
            <div className="container">
                <div className="header-left">
                    <a href="/" className="logo"><img src="/img/logo.png" width={175} height={31}></img></a>
                </div>
                <div className="header-middle d-none d-lg-flex">
                    <nav className="nav">
                        <a href="/" className="nav-link px-3 text-dark">Home</a>
                        <a href="/about-us" className="nav-link px-3 text-dark">About</a>
                        <a href="/news-blogs" className="nav-link px-3 text-dark">News & Blogs </a>
                        <a href="/contact-us" className="nav-link px-3 text-dark">Contact Us</a>
                    </nav>
                </div>
                {getusertoken == null ? <>
                    <div className="header-right justify-content-end">
                        <button type="button" className="btn" onClick={() => { settype('signin'); handlelogin() }}>Sign In</button>
                        <button type="button" className="btn btn-primary" onClick={() => { settype('join'); handlelogin() }}>Join</button>
                    </div>
                </> : <>
                    {getusertoken !== null
                        ? <>
                            <div className="header-right justify-content-end" onClick={() => { navigate('/dashboard') }}>
                                <img class="rounded-circle header-profile-user" src="/img/avatar-1.jpg" alt="Header Avatar" style={{ width: '35px' }} />
                                <div className="ms-3">
                                    <span>{contextValues.rowUserData.user_name}</span><br></br>
                                    <span>{contextValues.rowUserData.user_email}</span>
                                </div>
                            </div>
                        </> : ''
                    }

                </>}

                {showlogin && <Loginmodal showlogin={showlogin} handleClose={handleClose} setshowlogin={setshowlogin} type={type}></Loginmodal>}
            </div>
        </header>
    </>)
}

export default Header