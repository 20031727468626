
import React, { useEffect, useRef, useState,useContext } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useNavigate } from "react-router-dom";
import Accountsidebar from "./account_sidebar";
import { Alert } from "react-bootstrap";
import { ApiService } from "../../component/Services/apiservices";
import DataContext from "../../component/Elements/context";


const MyProfile=()=>{
  const contextValues = useContext(DataContext)
  const navigate = useNavigate();
  const [successmsg, setsuccessmsg] = useState("");
  const [UserData, setUserData] = useState({});
  const [errormsg, seterrormessage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const didMountRef = useRef(true);
  const Userdata = JSON.parse(localStorage.getItem("USER_SESSION"));

  useEffect(() => {
    if (didMountRef.current) {
      getUserdatadetail();
    }
    didMountRef.current = false;
  }, []);

  const getUserdatadetail = () => {
    ApiService.fetchData("/user-detail").then((res) => {
      if (res?.status == "success") {
        setUserData(res?.userData);
      } else if (res.status == 'session_expired') {
        localStorage.removeItem('USER_TOKEN')
        window.location.href = '/'
      }
    }).catch((error) => { });
  };

  const handleUpdateInput = (e) => {
    seterrormessage("");
    const { name, value } = e.target;
    setUserData({
      ...UserData,
      [name]: value,
    });
  };

  const Profileupdate = (e) => {
    e.preventDefault();
    if (UserData?.user_name == "" || UserData?.user_name == null) {
      seterrormessage('error', "Please enter full name");
      return;
    }
    setisLoading(true);
    const dataString = {
      user_name: UserData?.user_name,
    };
    ApiService
      .postData('/update-profile', dataString)
      .then((res) => {
        if (res?.status == "success") {
          setisLoading(false);
          contextValues.setRowUserData(res?.userData)
          setsuccessmsg(res.message)
          setTimeout(()=>{
            window.location.reload()
          }, 500)
        } else if (res.status=='error' &&(res.message == 'session_expired'||  res.message === 'Account Inactive'|| res.message === 'User not found')) {
          localStorage.removeItem('USER_TOKEN')
          window.location.href = '/'
        }

        else {
          seterrormessage(res.message)
          setisLoading(false);
        }
      });
  };
  const navigateback = (e) => {
    e.preventDefault()

    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };
  
    
    return(<>
   <Header></Header>
    <section className="section-gap-md">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Accountsidebar />
          </div>
          <div className="col-lg-9">
                <div className="ccpanel">
                  <div className="ccpanel-header">
                    <h5 className="mb-0">My Profile</h5>
                  </div>
                  <div className="ccpanel-body">
                    {errormsg && (

                      <Alert variant="danger">{errormsg}</Alert>

                    )}
                    {successmsg && <Alert variant="success">{successmsg}</Alert>}
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label htmlFor="user_fname">Full Name</label>
                          <input
                            className="form-control required"
                            type="text"
                            placeholder="Full Name"
                            defaultValue={UserData?.user_name}
                            name="user_name"
                            onChange={(e) => {
                              handleUpdateInput(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="user_email" className="form-label">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="user_email"
                            className="form-control required"
                            placeholder="Email Address"
                            value={UserData?.user_email}
                            disabled={true}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="user_mobile" className="form-label">
                            Mobile Number
                          </label>
                          <input
                            type="number"
                            name="user_mobile"
                            className="form-control required"
                            placeholder="Mobile Number"
                            disabled={true}
                            value={UserData?.user_mobileno}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="ccpanel-footer">
                    <button
                      className="btn btn-primary btn-md"
                      type="button"
                      name="submit"
                      onClick={(e) => {
                        Profileupdate(e);

                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <img
                          src="/img/loder01.gif"
                          width="60px"
                          height="11px"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>

              </div>
        </div>
      </div>
    </section>

    <Footer></Footer>
    
    </>)
}

export default MyProfile