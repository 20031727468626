const Propertycards = () => {
    return (<>
        <section className="section-gap-md">
            <div className="container">
                <div className="section-title text-center">
                <h2>Explore All Things Property</h2>
                <p>With market leading insights from proptrail, use your property dashboard to stay on top of:</p>
                </div>
                <div className="row">
                <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/coin.png" alt='coin'></img></div>
                            <div className="propboxContent">
                                <h2>Calculate your property's cashflow</h2>
                                <p className="mb-0">Proptrail can provide you estimated cashflow in less than a minute.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/home.png" alt='home'></img></div>
                            <div className="propboxContent">
                                <h2>What-If analysis</h2>
                                <p className="mb-0">Discover how your property performs under various scenarios.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/chart.png" alt='chart'></img></div>
                            <div className="propboxContent">
                                <h2>Identify a property that aligns with your strategy.</h2>
                                <p className="mb-0">Validate whether a property matches your strategy.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="propbox">
                            <div className="propboxMedia"><img src="/img/sold.png" alt='sold'></img></div>
                            <div className="propboxContent">
                                <h2>User-Friendly Dashboards</h2>
                                <p className="mb-0">Easily visualize and understand your property data with clear, user-friendly dashboards.</p>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>


    </>)
}

export default Propertycards