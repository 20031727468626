import React, { useState, useEffect, useRef } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useForm, } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../component/utils/loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { error } from "hot-formula-parser";
let apiServices = new ApiService()
const Purchasedetail = () => {
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();
    const { id } = useParams()
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    const [propertyData, setPropertyData] = useState("")
    const [Bank, setBankData] = useState([])
    const [settlementstatus, setsettlementstatus] = useState("")
    const [datechngesettle, setchangedatesettle] = useState("")
    const [InterestdefaultValues, setInterestdefaultvalue] = useState({})
    const [sendcondidate, setsendcondi] = useState("")
    const [sendsettlementdate, setsendsettle] = useState("")
    const [showloader, setshowloader] = useState(false)
    const [datechnge, setchangedate] = useState("")
    const [Errorobject, setErrorobject] = useState({ purchase_percentage_fixed: "", purchase_percentage_variable: "" })


    useEffect(() => {
        const dataString = {
            id: id
        }
        if (didMountRef.current) {
            apiServices.getpropertydataPostRequest(dataString).then(res => {
                if (res.data.status == "success") {

                    if (res?.data?.purschasedata !== null) {

                        if (res?.data?.purschasedata?.purchase_conditional_date !== null) {
                            setsettlementstatus("conditional");
                        } else if (res?.data?.purschasedata?.purchase_unconitional_date !== null) {
                            setsettlementstatus("unconditional");
                        }
                        setValue("purchase_bank_name", res.data?.purschasedata?.purchase_bank_name);
                        setValue("purchase_intrest_rate", res.data?.purschasedata?.purchase_intrest_rate);
                        setValue("purchase_is_other_agent", res.data?.purschasedata?.purchase_is_other_agent);
                        setValue("purchase_is_buyer_agent", res.data?.purschasedata?.purchase_is_buyer_agent);
                        setValue("purchase_is_rebate", res.data?.purschasedata?.purchase_is_rebate);
                        setValue("purchase_loan_split", res.data?.purschasedata?.purchase_loan_split);
                        setValue("purchase_interest_fixed", res?.data?.purschasedata?.purchase_interest_fixed);
                        setValue("purchase_percentage_fixed", res?.data?.purschasedata?.purchase_percentage_fixed);
                        setValue("purchase_percentage_variable", res?.data?.purschasedata?.purchase_percentage_variable);
                        setValue("purchase_interest_variable", res?.data?.purschasedata?.purchase_interest_variable);
                        setValue("purchase_rental_pw", res?.data?.purschasedata?.purchase_rental_pw);
                        setValue("purchase_contract_date", moment(res?.data?.purschasedata?.purchase_contract_date).format('YYYY-MM-DD'));
                        setValue("purchase_unconitional_date", res?.data?.purschasedata?.purchase_unconitional_date);
                        setValue("purchase_conditional_date", res?.data?.purschasedata?.purchase_conditional_date);
                        setValue("purchase_settelment_date", res?.data?.purschasedata?.purchase_settelment_date);
                        setValue("lmiamount", res?.data?.purschasedata?.purchase_lmi_amt);
                        setValue("stampdutyamount", res?.data?.purschasedata?.purchase_stamp_duty_amt);
                        setValue("otherbate", res?.data?.purschasedata?.purchase_other_bate);
                        setValue("buyersagentfee", res?.data?.purschasedata?.purchase_buyers_agent_fee);
                        setValue("otheragentfee", res?.data?.purschasedata?.purchase_other_agent_fee);
                        setValue("adjustedcouncilrates", res?.data?.purschasedata?.purchase_adjusted_council_rates);
                        setValue("adjustedwaterrates", res?.data?.purschasedata?.purchase_adjusted_water_rates);
                        setValue("loantype", res?.data?.purschasedata?.purchase_loan_type);
                        setValue("termfixed", res?.data?.purschasedata?.purchase_term_fixed);
                        setValue("termvariable", res?.data?.purschasedata?.purchase_term_variable);
                        setValue("noofunits", res?.data?.purschasedata?.purchase_noof_unit);
                        setValue("fhogamount", res.data.purschasedata?.purchase_fhog_amt);
                        setValue("firsthomeowner", res.data.purschasedata?.purchase_first_home_owner);
                        setValue("lmiapplicable", res.data.purschasedata?.purchase_lmi_app);
                        setValue("structuralinspectionfee", res.data.purschasedata?.purchase_struc_inspect_fee);
                        setValue("structuralinspectionfeepaid", res.data.purschasedata?.purchase_struc_inspect_fee_paidstatus);
                        setValue("buildinginsurancefee", res.data.purschasedata?.purchase_building_ins);
                        setValue("buildinginsurancefeepaid", res.data.purschasedata?.purchase_building_ins_paidstatus);
                        setValue("timberinspectionfee", res.data.purschasedata?.purchase_timber_fee);
                        setValue("timberinspectionfeepaid", res.data.purschasedata?.purchase_timber_fee_paidstatus);
                        setValue("plumbinginspectionfee", res.data.purschasedata?.purchase_plumbing_fee);
                        setValue("plumbinginspectionfeepaid", res.data.purschasedata?.purchase_plumbing_fee_paidstatus);
                        setValue("electricalinspectionfee", res.data.purschasedata?.purchase_electrical_fee);
                        setValue("electricalinspectionfeepaid", res.data.purschasedata?.purchase_electrical_fee_paidstatus);
                        setValue("settlementagentfee", res.data.purschasedata?.purchase_settelment_fee);
                        setValue("settlementagentfeepaid", res.data.purschasedata?.purchase_settelment_fee_paidstatus);
                        setValue("pexafee", res.data.purschasedata?.purchase_pexa_fee);
                        setValue("pexafeepaid", res.data.purschasedata?.purchase_pexa_fee_paidstatus);
                        setValue("bankmortgagesetupfee", res.data.purschasedata?.purchase_bank_mortgage_fee);
                        setValue("bankmortgagesetupfeepaid", res.data?.purschasedata?.purchase_bank_mortgage_fee_paidstatus);
                        setValue("landgateregistrat", res.data?.purschasedata?.purchase_bank_land_gate_fee);
                        setValue("landgateregistratpaid", res.data?.purschasedata?.purchase_bank_land_gate_fee_paidstatus);
                        setValue("maintainceallowance", res.data?.purschasedata?.maintainceallowance);
                        setValue("potential_deduction", res.data?.purschasedata?.purchase_potential_deduction);
                    }

                    setPropertyData(res.data.data)

                }
            }).catch((errors) => { })
            apiServices.getdefaultvaluesrequest().then(res => {
                if (res?.data?.status == "success") {
                    //   setdefaultValues(res?.data?.defaultvalues)
                    if (res.data.status == "success") {
                        if (res?.data?.defaultvalues !== null) {
                            setInterestdefaultvalue(prevState => ({
                                ...prevState,
                                ['purchase_interest_fixed']: res?.data?.defaultvalues?.dfinterest_fixed,
                                ['purchase_interest_variable']: res?.data?.defaultvalues?.dfinterest_variable,
                            }));

                            setValue("otherbate", res?.data?.defaultvalues?.dfother_rebate);
                            setValue("buyersagentfee", res?.data?.defaultvalues?.dfbuyer_agent_fee);
                            setValue("otheragentfee", res?.data?.defaultvalues?.dfother_agent_fee);
                            setValue("loantype", res?.data?.defaultvalues?.dfloan_type);
                            setValue("termfixed", res?.data?.defaultvalues?.dfterm_fixed);
                            setValue("purchase_interest_fixed", res?.data?.defaultvalues?.dfinterest_fixed);
                            setValue("purchase_interest_variable", res?.data?.defaultvalues?.dfinterest_variable);
                            setValue("termvariable", res?.data?.defaultvalues?.dfterm_variable);
                            setValue("noofunits", res?.data?.defaultvalues?.dfnoof_unit);
                            setValue("fhogamount", res.data.defaultvalues?.dffhog_amount);
                            setValue("firsthomeowner", res.data.defaultvalues?.dffhog_home_owner);
                            setValue("structuralinspectionfee", res.data.defaultvalues?.dfstructural_insp_cost);
                            setValue("buildinginsurancefee", res.data.defaultvalues?.dfbuilding_ins_cost);
                            setValue("timberinspectionfee", res.data.defaultvalues?.dfpest_insp_cost);
                            setValue("plumbinginspectionfee", res.data.defaultvalues?.dfplumbing_inst_cost);
                            setValue("electricalinspectionfee", res.data.defaultvalues?.dfelectricalinspectionfee);
                            setValue("settlementagentfee", res.data.defaultvalues?.dfsettlement_agent_fee);
                            setValue("pexafee", res.data.defaultvalues?.dfpexa_fee);
                            setValue("bankmortgagesetupfee", res.data.defaultvalues?.dfbank_mortage_setup);
                            setValue("landgateregistrat", res.data?.defaultvalues?.dfland_regis);
                            setValue("maintainceallowance", res.data?.defaultvalues?.dfmaintaince_allownce);
                        }
                    }
                }

            }).catch((errors) => { })
            getBankData()

        }
        didMountRef.current = false;
    })

    const getBankData = () => {
        ApiService.fetchData('/property/getaustraliabank').then((res)=>{
            if(res.status === 'success'){
                setBankData(res.data)
            }
            else{
                setBankData([])
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    const fixedvariable = {
        fixed: watch("purchase_percentage_fixed"),
        variable: watch("purchase_percentage_variable"),
        contractdate: watch("purchase_contract_date"),
        settlementstatus: watch("settlementstatus"),
        lmiapplicable: watch("lmiapplicable"),
        interestcheckbox: watch("purchase_loan_split"),
        purchaserebate: watch('purchase_is_rebate'),
        buyersagent: watch('purchase_is_buyer_agent'),
        otherreagent: watch('purchase_is_other_agent')


    }
    useEffect(() => {
        if (fixedvariable.contractdate !== "") {
            dateaftersomeday()
            dateaftersomedaysettle()
        }

        if (fixedvariable.interestcheckbox == 'no') {
            chnageInterestvalues()
        }
    }, [fixedvariable.contractdate, datechnge, datechngesettle, fixedvariable.interestcheckbox])
    const advanceddetailcheckbox = watch("advanceddetails")

    const chnageInterestvalues = () => {
        if (fixedvariable.interestcheckbox == 'no') {
            setValue("purchase_interest_fixed", InterestdefaultValues?.purchase_interest_fixed);
            setValue("purchase_percentage_fixed", 0);
            setValue("purchase_percentage_variable", 100);
            setValue("purchase_interest_variable", InterestdefaultValues?.purchase_interest_variable);
        }
    }
    // chnageInterestvalues()
    const firsthomeowner = watch("firsthomeowner")
    const onSubmit = (data) => {
        if (fixedvariable.variable !== '' && fixedvariable.fixed !== '') {

            if (settlementstatus == "") {
                setErrorobject({
                    ...Errorobject,
                    settlementstatus: "Please select conditional OR unconditional date",
                });
                return
            }
            if (Math.round(fixedvariable.fixed) + Math.round(fixedvariable.variable) < 100 || Math.round(fixedvariable.fixed) + Math.round(fixedvariable.variable) > 100) {
                setErrorobject({
                    ...Errorobject,
                    purchase_percentage_fixed: "Total percentage of variable and fixed should be of 100",
                    purchase_percentage_variable: "Total percentage of variable and fixed should be of 100"
                });
                return false
            }
            else {
                const condisecnd = parseInt(data.buildinginsurancefee) + parseInt(data.structuralinspectionfee) + parseInt(data.timberinspectionfee) + parseInt(data.plumbinginspectionfee) + parseInt(data.electricalinspectionfee)
                const conditionthrd = (parseInt(data.settlementagentfee) + parseInt(data.otheragentfee) + parseInt(data.bankmortgagesetupfee) + parseInt(data.pexafee) + parseInt(data.landgateregistrat) + (data.lmiapplicable == "yes" ? parseInt(data.lmiamount) : 0) + parseInt(data.stampdutyamount) + parseInt(data.buyersagentfee)) - (parseInt(data.otherbate) + parseInt(data.adjustedwaterrates) + parseInt(data.adjustedcouncilrates))
                const uncondithrd = (parseInt(data.buildinginsurancefee) + parseInt(data.buyersagentfee) + parseInt(data.settlementagentfee) + parseInt(data.otheragentfee) + parseInt(data.bankmortgagesetupfee) + parseInt(data.pexafee) + parseInt(data.landgateregistrat) + (data.lmiapplicable == "yes" ? parseInt(data.lmiamount) : 0) + parseInt(data.stampdutyamount)) - (parseInt(data.otherbate) + parseInt(data.adjustedwaterrates) + parseInt(data.adjustedcouncilrates))

                const totalcash = (Number(parseInt(data.adjustedcouncilrates) +
                    parseInt(data.adjustedwaterrates) +
                    parseInt(propertyData?.property_purchase_deposit) +
                    parseInt(data.buyersagentfee) +
                    parseInt(data.otheragentfee) +
                    parseInt(data.bankmortgagesetupfee) +
                    parseInt(data.pexafee) +
                    parseInt(data.landgateregistrat) +
                    (data.lmiapplicable == "yes" ? parseInt(data.lmiamount) : 0) +
                    parseInt(data.stampdutyamount) +
                    parseInt(data.buildinginsurancefee) +
                    parseInt(data.settlementagentfee) +
                    parseInt(data.structuralinspectionfee) +
                    parseInt(data.timberinspectionfee) +
                    parseInt(data.plumbinginspectionfee) +
                    parseInt(data.electricalinspectionfee)
                ) -
                    Number(parseInt(data.otherbate) + parseInt(data.fhogamount))
                )
                setshowloader(true)
                const dataString = {
                    purchase_bank_name: data.purchase_bank_name,
                    purchase_intrest_rate: fixedvariable.interestcheckbox == 'yes' ? 0 : data.purchase_intrest_rate,
                    purchase_loan_split: data.purchase_loan_split,
                    purchase_is_other_agent: data.purchase_is_other_agent,
                    purchase_is_buyer_agent: data.purchase_is_buyer_agent,
                    purchase_is_rebate: data.purchase_is_rebate,
                    purchase_interest_fixed: data.purchase_interest_fixed,
                    purchase_percentage_fixed: data.purchase_percentage_fixed,
                    purchase_percentage_variable: data.purchase_percentage_variable,
                    purchase_interest_variable: data.purchase_interest_variable,
                    purchase_rental_pw: data.purchase_rental_pw,
                    purchase_contract_date: data.purchase_contract_date,
                    purchase_unconitional_date: settlementstatus == "unconditional" ? new Date(sendcondidate) : "",
                    purchase_conditional_date: settlementstatus == "conditional" ? new Date(sendcondidate) : "",
                    purchase_settelment_date: new Date(sendsettlementdate),
                    purchase_lmi_amt: data.lmiamount,
                    purchase_stamp_duty_amt: data.stampdutyamount,
                    purchase_other_bate: data.otherbate,
                    purchase_buyers_agent_fee: data.buyersagentfee,
                    purchase_other_agent_fee: data.otheragentfee,
                    purchase_adjusted_council_rates: data.adjustedcouncilrates,
                    purchase_adjusted_water_rates: data.adjustedwaterrates,
                    purchase_total_cash_req: totalcash,
                    purchase_uncondit_thrdamount: uncondithrd,
                    purchase_condit_thrdamount: conditionthrd,
                    purchase_condit_secamount: condisecnd,
                    purchase_potential_deduction: data.potential_deduction,
                    // purchase_bank_value: data.bankvaluation,
                    // purchase_loan_amount: data.loanamount,
                    // purchase_deposit: data.deposit,
                    purchase_loan_type: data.loantype,
                    purchase_term_fixed: data.termfixed,
                    purchase_term_variable: data.termvariable,
                    purchase_noof_unit: data.noofunits,
                    purchase_fhog_amt: data.fhogamount,
                    purchase_first_home_owner: data.firsthomeowner,
                    purchase_lmi_app: data.lmiapplicable,
                    purchase_struc_inspect_fee: data.structuralinspectionfee,
                    purchase_struc_inspect_fee_paidstatus: data.structuralinspectionfeepaid,
                    purchase_building_ins: data.buildinginsurancefee,
                    purchase_building_ins_paidstatus: data.buildinginsurancefeepaid,
                    purchase_timber_fee: data.timberinspectionfee,
                    purchase_timber_fee_paidstatus: data.timberinspectionfeepaid,
                    purchase_plumbing_fee: data.plumbinginspectionfee,
                    purchase_plumbing_fee_paidstatus: data.plumbinginspectionfeepaid,
                    purchase_electrical_fee: data.electricalinspectionfee,
                    purchase_electrical_fee_paidstatus: data.electricalinspectionfeepaid,
                    purchase_settelment_fee: data.settlementagentfee,
                    purchase_settelment_fee_paidstatus: data.settlementagentfeepaid,
                    purchase_pexa_fee: data.pexafee,
                    purchase_pexa_fee_paidstatus: data.pexafeepaid,
                    purchase_bank_mortgage_fee: data.bankmortgagesetupfee,
                    purchase_bank_mortgage_fee_paidstatus: data.bankmortgagesetupfeepaid,
                    purchase_bank_land_gate_fee: data.landgateregistrat,
                    purchase_bank_land_gate_fee_paidstatus: data.landgateregistratpaid,
                    maintainceallowance: data.maintainceallowance,
                    purchase_property_id: id,
                }

                apiServices.savepurchasedetailPostRequest(dataString).then(res => {
                    if (res.data.status == "success") {
                        setshowloader(false)
                        toast.success(res.data.message)
                        Navigate('/purchase-summary/' + id)
                    }
                    else if (res?.data?.status == "error" && (res.data.message == 'session_expired' || res.data.message === 'User not found')) {
                        toast.error(res?.data?.message)
                        localStorage.removeItem("USER_TOKEN")
                        setshowloader(false)
                        Navigate('/')
                    }
                    else if (res?.data?.status === "error") {
                        toast.error(res?.data?.message);
                        setshowloader(false);
                    }
                   
                }).catch((error) => {
                    setTimeout(() => {
                        setshowloader(false)
                    }, 500)
                })
            }
        }
    };

    const dateaftersomeday = (value) => {
        const startDate = new Date(fixedvariable.contractdate);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 21);
        setsendcondi(moment(endDate).format(`MM-DD-YYYY`))
        setchangedate(moment(endDate).format(`DD-MM-YYYY`))

    }
    const dateaftersomedaysettle = () => {
        const date = fixedvariable.contractdate
        const startDate = new Date(fixedvariable.contractdate); // Assuming fixedvariable.contractdate is a valid date string or timestamp
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 42);
        setsendsettle(moment(endDate).format(`MM-DD-YYYY`))
        setchangedatesettle(moment(endDate).format(`DD-MM-YYYY`))
        setValue("purchase_settelment_date", moment(endDate).format(`DD-MM-YYYY`));
    }
    const rentalpwfunction = () => {
        setValue("purchase_percentage_variable", Math.round(100 - getValues("purchase_percentage_fixed")))
    }
    return (<>
        <Header />
        <section className="section-gap-md">
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3">
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Bank</label>
                                <select name="purchase_bank_name"
                                {...register("purchase_bank_name", {
                                })}
                                class="custom-select"
                            >
                                <option >Select Bank</option>
                                {Bank.map((item, index) => {
                                    return <option key={index} value={item.property_bank_name}>{item.property_bank_name}</option>
                                })}
                            </select>
                               
                                {/* {errors.purchase_bank_name && errors.purchase_bank_name.type === "required" && (
                                <small className="text-danger">Bank Name is required.</small>
                            )} */}
                            </div>
                        </div>
                        {fixedvariable.interestcheckbox == 'no' &&
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Current interest rate</label>
                                    <input
                                        type="number"
                                        name="purchase_intrest_rate"
                                        step="any"
                                        {...register("purchase_intrest_rate", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_intrest_rate && errors.purchase_intrest_rate.type === "required" && (
                                        <small className="text-danger">Current Interest Rate is required.</small>
                                    )}
                                </div>
                            </div>}


                        <div className="col-lg-4">
                            <label>Is Loan amount split into fixed and variable ?</label>
                            <select name="purchase_loan_split"
                                {...register("purchase_loan_split", {
                                })}
                                class="custom-select"
                            >
                                <option value="yes">Yes</option>
                                <option value="no" selected>No</option>
                            </select>
                        </div>

                        {fixedvariable.interestcheckbox == 'yes' && <>
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Interest Fixed</label>
                                    <input
                                        type="number"

                                        name="purchase_interest_fixed"
                                        step="any"
                                        {...register("purchase_interest_fixed", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_interest_fixed && errors.purchase_interest_fixed.type === "required" && (
                                        <small className="text-danger">Interest Fixed is required.</small>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Interest Variable</label>
                                    <input
                                        type="number"

                                        name="purchase_interest_variable"
                                        step="any"
                                        {...register("purchase_interest_variable", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_interest_variable && errors.purchase_interest_variable.type === "required" && (
                                        <small className="text-danger">Interest Variable is required.</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Percentage Fixed</label>
                                    <input
                                        type="number"

                                        name="purchase_percentage_fixed"
                                        step="any"
                                        defaultValue={0}
                                        onKeyUp={rentalpwfunction}
                                        {...register("purchase_percentage_fixed", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_percentage_fixed && errors.purchase_percentage_fixed.type === "required" && (
                                        <small className="text-danger">Percentage Fixed is required.</small>
                                    )}
                                    {Errorobject.purchase_percentage_fixed && <p className="text-danger">{Errorobject.purchase_percentage_fixed}</p>}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Percentage Variable</label>
                                    <input
                                        type="number"

                                        name="purchase_percentage_variable"
                                        step="any"
                                        defaultValue={100}
                                        {...register("purchase_percentage_variable", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_percentage_variable && errors.purchase_percentage_variable.type === "required" && (
                                        <small className="text-danger">Percentage Variable is required.</small>
                                    )}

                                    {Errorobject.purchase_percentage_variable && <p className="text-danger">{Errorobject.purchase_percentage_variable}</p>}
                                </div>
                            </div>

                        </>}



                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Rental pw</label>
                                <input
                                    type="number"
                                    name="purchase_rental_pw"
                                    step="any"
                                    {...register("purchase_rental_pw", {
                                        required: true,
                                    })}
                                />
                                {errors.purchase_rental_pw && errors.purchase_rental_pw.type === "required" && (
                                    <small className="text-danger">Rental pw is required.</small>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Contract Date</label>
                                <input
                                    type="date"
                                    name="purchase_contract_date"
                                    // onKeyUp={(e) => dateaftersomeday(e.target.value)}
                                    {...register("purchase_contract_date", {
                                        required: true,

                                    })}
                                />
                                {errors.purchase_contract_date && errors.purchase_contract_date.type === "required" && (
                                    <small className="text-danger">Contract Date is required.</small>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Settlement Date</label>
                                <input
                                    defaultValue={datechngesettle !== "Invalid date" ? datechngesettle : ""}
                                    name="purchase_settelment_date"
                                    // disabled={true}
                                    {...register("purchase_settelment_date", {
                                        required: true,

                                    })}
                                />
                                {errors.purchase_settelment_date && errors.purchase_settelment_date.type === "required" && (
                                    <small className="text-danger">Settlement Date is required.</small>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex mb-20">
                                <input
                                    {...register("radio")}
                                    type="radio"
                                    value="conditional"
                                    onChange={(e) => { setsettlementstatus(e.target.value); setErrorobject({}) }}
                                    checked={settlementstatus === "conditional"}
                                    name="settlementstatus"
                                />
                                <span className="ml-10"> Conditional Settlement</span></div>
                            <div className="d-flex mb-20">
                                <input
                                    {...register("radio")}
                                    type="radio"
                                    value="unconditional"
                                    onChange={(e) => { setsettlementstatus(e.target.value); setErrorobject({}) }}
                                    name="settlementstatus"
                                    checked={settlementstatus === "unconditional"}
                                />
                                <span className="ml-10">  Unconditional Settlement</span></div>


                            {Errorobject.settlementstatus && <p className="text-danger">{Errorobject.settlementstatus}</p>}
                        </div>
                        {settlementstatus == "unconditional" ? <>

                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Unconditional Settlement Date</label>
                                    <input
                                        name="unconditionalsettlementdate"
                                        defaultValue={datechnge !== "Invalid date" ? datechnge : ""}
                                        disabled={true}
                                    // defaultValue={dateaftersomeday !== "Invalid date" ? dateaftersomeday : ""}
                                    />
                                </div>
                            </div>
                        </> : <>
                            {settlementstatus == "conditional" && <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Conditional Settlement Date</label>
                                    <input
                                        name="conditionalsettlementdate"
                                        defaultValue={datechnge !== "Invalid date" ? datechnge : ""}
                                        disabled={true}


                                    />
                                    {/* {errors.conditionalsettlementdate && errors.conditionalsettlementdate.type === "required" && (
                                    <small className="text-danger">Conditional Settlement Date is required.</small>
                                )} */}

                                </div>
                            </div>}
                        </>}
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Lmi Applicable</label>
                                <select name="lmiapplicable"
                                    {...register("lmiapplicable", {
                                    })}
                                    class="custom-select"
                                >
                                    <option value="yes">Yes</option>
                                    <option value="no" selected>No</option>
                                </select>
                            </div>
                        </div>
                        {fixedvariable.lmiapplicable == "yes" && <>
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>LMI Amount</label>
                                    <input
                                        type="number"
                                        defaultValue={0}
                                        name="lmiamount"
                                        {...register("lmiamount", {
                                            required: true,
                                        })}
                                    />
                                    {errors.lmiamount && errors.lmiamount.type === "required" && (
                                        <small className="text-danger">LMI Amount is required.</small>
                                    )}

                                </div>
                            </div>


                        </>}

                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Stamp Duty Amount</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="stampdutyamount"
                                    {...register("stampdutyamount", {
                                        required: true,
                                    })}
                                // onKeyUp={rentalpwfunction}
                                />
                                {errors.stampdutyamount && errors.stampdutyamount.type === "required" && (
                                    <small className="text-danger">Stamp Duty Amount is required.</small>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <label>Was there any rebate? </label>
                            <select name="purchase_is_rebate"
                                {...register("purchase_is_rebate", {
                                })}
                                class="custom-select"
                            >
                                <option value="yes">Yes</option>
                                <option value="no" selected>No</option>
                            </select>
                        </div>
                        {fixedvariable.purchaserebate == "yes" && (
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Other Rebate</label>
                                    <input
                                        type="number"
                                        step="any"
                                        name="otherbate"
                                        // defaultValue={defaultValues?.dfother_rebate}
                                        {...register("otherbate", {
                                            required: true,

                                        })}
                                    // onKeyUp={rentalpwfunction}
                                    />
                                    {errors.otherbate && errors.otherbate.type === "required" && (
                                        <small className="text-danger">Other Bate is required.</small>
                                    )}

                                </div>
                            </div>
                        )}
                        <div className="col-lg-4">
                            <label>Did you use a buyers agent?</label>
                            <select name="purchase_is_buyer_agent"
                                {...register("purchase_is_buyer_agent", {
                                })}
                                class="custom-select"
                            >
                                <option value="yes">Yes</option>
                                <option value="no" selected>No</option>
                            </select>
                        </div>
                        {fixedvariable.buyersagent == 'yes' && (
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Buyers Agent Fee</label>
                                    <input
                                        type="number"
                                        name="buyersagentfee"
                                        step="any"
                                        // defaultValue={defaultValues?.dfbuyer_agent_fee}
                                        {...register("buyersagentfee", {
                                            required: true,

                                        })}
                                    // onKeyUp={rentalpwfunction}
                                    />
                                    {errors.buyersagentfee && errors.buyersagentfee.type === "required" && (
                                        <small className="text-danger">Buyers Agent Fee is required.</small>
                                    )}

                                </div>
                            </div>

                        )}
                        <div className="col-lg-4">
                            <label>Did you use any other agent?</label>
                            <select name="purchase_is_other_agent"
                                {...register("purchase_is_other_agent", {
                                })}
                                class="custom-select"
                            >
                                <option value="yes">Yes</option>
                                <option value="no" selected>No</option>
                            </select>
                        </div>
                        {fixedvariable.otherreagent == 'yes' && (
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Other Agent Fee</label>
                                    <input
                                        type="number"
                                        name="otheragentfee"
                                        step="any"
                                        // defaultValue={defaultValues?.dfother_agent_fee}
                                        {...register("otheragentfee", {
                                            required: true,

                                        })}

                                    />
                                    {errors.otheragentfee && errors.otheragentfee.type === "required" && (
                                        <small className="text-danger">Other Agent Fee is required.</small>
                                    )}

                                </div>
                            </div>
                        )}

                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Adjusted Water Rates</label>
                                <input
                                    type="number"
                                    name="adjustedwaterrates"
                                    step="any"
                                    {...register("adjustedwaterrates", {
                                        required: true,

                                    })}

                                />
                                {errors.adjustedwaterrates && errors.adjustedwaterrates.type === "required" && (
                                    <small className="text-danger">Adjusted Water Rates is required.</small>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Adjusted Council Rates</label>
                                <input
                                    type="number"
                                    name="adjustedcouncilrates"
                                    step="any"
                                    {...register("adjustedcouncilrates", {
                                        required: true,

                                    })}
                                />
                                {errors.adjustedcouncilrates && errors.adjustedcouncilrates.type === "required" && (
                                    <small className="text-danger">Adjusted Council Rates is required.</small>
                                )}

                            </div>
                        </div>


                        <div className="col-lg-4">

                            <div class="d-flex mb-20">
                                <input
                                    type='checkbox'
                                    name="advanceddetails"
                                    {...register("advanceddetails", {
                                    })}
                                />
                                <span className="ml-10">Advanced Details</span>
                            </div>
                        </div>
                        {advanceddetailcheckbox && <>
                            <div className="row mt-5" >

                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Select Loan Type</label>

                                        <select {...register("loantype", {
                                            required: true,
                                        })} class="custom-select" name="loantype"
                                        // defaultValue={defaultValues?.dfloan_type}
                                        >
                                            <option value="P&I">P&I</option>
                                            <option value="Interest">Interest</option>
                                        </select>
                                        {errors.loantype && errors.loantype.type === "required" && (
                                            <small className="text-danger">Loan Type is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Term Fixed</label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfterm_fixed}
                                            name="termfixed"
                                            {...register("termfixed", {
                                                required: true,

                                            })}
                                        />
                                        {errors.termfixed && errors.termfixed.type === "required" && (
                                            <small className="text-danger">Term Fixed is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Term Variable</label>
                                        <input
                                            type="number"
                                            // defaultValue={defaultValues?.dfterm_variable}
                                            name="termvariable"
                                            step="any"
                                            {...register("termvariable", {
                                                required: true,

                                            })}
                                        />
                                        {errors.termvariable && errors.termvariable.type === "required" && (
                                            <small className="text-danger">Term Variable is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Noof Units</label>
                                        <input
                                            type="number"
                                            // defaultValue={defaultValues?.dfnoof_unit}
                                            name="noofunits"
                                            step="any"
                                            {...register("noofunits", {
                                                required: true,

                                            })}
                                        />
                                        {errors.noofunits && errors.noofunits.type === "required" && (
                                            <small className="text-danger">Noof Units is required.</small>
                                        )}

                                    </div>
                                </div>
                                {firsthomeowner == "yes" && <div className="col-lg-6">
                                    <div class="form-group ">
                                        <label> FHOG amount </label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input
                                                type="amount"  // Change type to text
                                                name="fhogamount"
                                                step="any"
                                                // defaultValue={defaultValues?.dffhog_amount}
                                                {...register("fhogamount", {
                                                    required: true,
                                                })}
                                            // onKeyUp={rentalpwfunction}
                                            />
                                        </div>

                                        {errors.fhogamount && errors.fhogamount.type === "required" && (
                                            <small className="text-danger">FHOG amount is required.</small>
                                        )}

                                    </div>
                                </div>}

                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>First Home Owner</label>
                                        <select name="firsthomeowner" class="custom-select"
                                            {...register("firsthomeowner", {
                                            })}

                                        // defaultValue={defaultValues?.dffhog_home_owner}
                                        >
                                            <option value="yes">Yes</option>
                                            <option selected value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Building insurance cost</label>
                                        <input
                                            type="number"
                                            name="buildinginsurancefee"
                                            step="any"
                                            // defaultValue={defaultValues?.dfbuilding_ins_cost}
                                            {...register("buildinginsurancefee", {
                                                required: true,
                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.buildinginsurancefee && errors.buildinginsurancefee.type === "required" && (
                                            <small className="text-danger">Building insurance cost is required.</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="buildinginsurancefeepaid"

                                            {...register("buildinginsurancefeepaid", {
                                                required: true,
                                            })}>
                                            <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Structural inspection cost</label>
                                        <input
                                            type="number"
                                            name="structuralinspectionfee"
                                            step="any"
                                            // defaultValue={defaultValues?.dfstructural_insp_cost}
                                            {...register("structuralinspectionfee", {
                                                required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.structuralinspectionfee && errors.structuralinspectionfee.type === "required" && (
                                            <small className="text-danger">Structural inspection cost is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select name="structuralinspectionfeepaid"
                                            class="custom-select"
                                            {...register("structuralinspectionfeepaid", {
                                                required: true,
                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label> pest inspection cost </label>
                                        <input
                                            type="number"
                                            // defaultValue={defaultValues?.dfpest_insp_cost}
                                            name="timberinspectionfee"
                                            step="any"
                                            {...register("timberinspectionfee", {
                                                required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.timberinspectionfee && errors.timberinspectionfee.type === "required" && (
                                            <small className="text-danger"> pest inspection cost is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="timberinspectionfeepaid"
                                            {...register("timberinspectionfeepaid", {
                                                required: true,
                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Plumbing inspection cost </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfplumbing_inst_cost}
                                            name="plumbinginspectionfee"
                                            {...register("plumbinginspectionfee", {
                                                required: true,

                                            })}
                                        />
                                        {errors.plumbinginspectionfee && errors.plumbinginspectionfee.type === "required" && (
                                            <small className="text-danger">Plumbing inspection cost is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="plumbinginspectionfeepaid"
                                            {...register("plumbinginspectionfeepaid", {
                                                required: true,
                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Electrical inspection cost </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfelectricalinspectionfee}
                                            name="electricalinspectionfee"
                                            {...register("electricalinspectionfee", {
                                                required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.electricalinspectionfee && errors.electricalinspectionfee.type === "required" && (
                                            <small className="text-danger">Electrical inspection cost is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="electricalinspectionfeepaid"
                                            {...register("electricalinspectionfeepaid", {
                                                required: true,
                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Settlement Agent fee </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfsettlement_agent_fee}
                                            name="settlementagentfee"
                                            {...register("settlementagentfee", {
                                                required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.settlementagentfee && errors.settlementagentfee.type === "required" && (
                                            <small className="text-danger">Settlement Agent fee is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="settlementagentfeepaid"
                                            {...register("settlementagentfeepaid", {
                                                required: true,
                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Bank Mortgage setup fee </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfbank_mortage_setup}
                                            name="bankmortgagesetupfee"
                                            {...register("bankmortgagesetupfee", {
                                                required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.bankmortgagesetupfee && errors.bankmortgagesetupfee.type === "required" && (
                                            <small className="text-danger"> Bank Mortgage setup fee is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="bankmortgagesetupfeepaid"
                                            {...register("bankmortgagesetupfeepaid", {
                                                required: true,

                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Pexa fee </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfpexa_fee}
                                            name="pexafee"
                                            {...register("pexafee", {
                                                required: true,

                                            })}
                                        />
                                        {errors.pexafee && errors.pexafee.type === "required" && (
                                            <small className="text-danger"> Pexa fee is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="pexafeepaid"
                                            {...register("pexafeepaid", {
                                                required: true,

                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Land Gate Registration </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfland_regis}
                                            name="landgateregistrat"
                                            {...register("landgateregistrat", {
                                                required: true,

                                            })}
                                        />
                                        {errors.landgateregistrat && errors.landgateregistrat.type === "required" && (
                                            <small className="text-danger"> Land Gate Registration is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="landgateregistratpaid"
                                            {...register("landgateregistratpaid", {
                                                required: true,

                                            })}>
                                             <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Maintaince Allowance </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfmaintaince_allownce}
                                            name="maintainceallowance"
                                            {...register("maintainceallowance", {
                                                required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.maintainceallowance && errors.maintainceallowance.type === "required" && (
                                            <small className="text-danger"> Maintainance allowance is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Potential Deduction</label>
                                        <input
                                            type="number"
                                            step="any"
                                            defaultValue={0}
                                            name="potential_deduction"
                                            {...register("potential_deduction", {
                                                // required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.potential_deduction && errors.potential_deduction.type === "required" && (
                                            <small className="text-danger"> Potential Deduction is required.</small>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </>}
                        <button type="submit" class="btn  btn-lg btn-primary" disabled={showloader}
                        >
                            {showloader ? (
                                <img src="/img/loder01.gif" width="60px" height="11px" />
                            ) : (
                                "Submit"
                            )}</button>
                    </div>
                </form>

            </div>
        </section>
        <Footer />
    </>)
}

export default Purchasedetail

















