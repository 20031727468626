import * as React from "react";
import { Range } from "react-range";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../component/Services/apiservices";
import Table from 'react-bootstrap/Table';
import { toast } from "react-toastify";
import Nav from 'react-bootstrap/Nav';
import SweetAlert from "react-bootstrap-sweetalert";
import ScenerioSummary from "../../component/scenerio_summary_annalysis..js";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataContext from "../../component/Elements/context.js";

const ChangeScenerio = () => {
    const contextValues = React.useContext(DataContext)
    const { id } = useParams()
    const [inflation, setInflation] = React.useState([1]);
    const [rentalgrowth, setrentalGrowth] = React.useState([1]);
    const [growthAsuump, setgrowthAsuump] = React.useState([1]);
    const [scenerioTableData, setScenerioTableData] = useState([]);
    const [showtable, setshowtable] = useState("summary")
    const [selectYear, setselectYear] = useState()
    const [showsceneriotable, setshowsceneriotable] = useState(false)
    const [sceneriores, setScenerioRes] = useState({})
    const [showsubmit, setshowsubmit] = useState(false)
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [showactionbtns, setshowactionbtns] = useState(false);
    const [showinputs, setshowinputs] = useState({})
    const [selectedObject, setselectObject] = useState({})
    const [purchasedata, setpurchasedata] = useState({})
    const [showloader, setshowloader] = useState(false)
    const [selectedValues, setSelectedValues] = useState(Array(scenerioTableData.length).fill("0")); // Initialize an array to store 
    const [show, setShow] = useState(false)
    const navigate = useNavigate()

    const getScenerioData = () => {
        let dataString = {
            scenerio_id: id
        }
        ApiService.postData(`/property/getsceneriodetail`, dataString)
            .then((res) => {
                if (res.status === 'success') {

                    setshowsceneriotable(true)
                    setScenerioTableData(res.scenerodetail.scenerio_table_data)
                } else if (res.status === 'error' && (res.message === 'session_expired' || res.message === 'Account Inactive')) {
                    localStorage.removeItem('USER_TOKEN');
                    navigate('/');
                }

            })
            .catch((error) => {
                console.error('Error fetching properties:', error);
            });
    };
    const handleSubmit = async () => {
        setshowloader(true)
        if (!selectYear) {
            toast.error('Please Enter year for scenerio')
            setshowloader(false)
            return
        }
        else if (selectYear <= 30 && selectYear >= 0) {
            const dataString = {
                property_id: id,
                inflations: inflation[0],
                rentalgrowth: rentalgrowth[0],
                growthassump: growthAsuump[0],
                year: Number(selectYear)
            }
            ApiService.postData('property/createpropertyscenerio', dataString).then((res) => {
                if (res.status == 'success') {
                    setshowloader(false)
                    setshowsceneriotable(true)
                    setScenerioRes(res.propertysceneriodata)
                    setScenerioTableData(res.propertysceneriodata.scenerio_table_data)
                    setpurchasedata(res.purchasedata)

                } else if (res?.status == "error" && res.message == 'session_expired') {
                    toast.error(res?.data?.message)
                    localStorage.removeItem("USER_TOKEN")
                    setshowloader(false)
                    navigate('/')
                }
                else if (res?.status === "error") {
                    toast.error(res?.data?.message);
                    setshowloader(false);
                }
            })
        }
        else {
            toast.error('Please Enter valid year count from the range of 0 to 30')
            setshowloader(false)
            return
        }

    }
    const changeStatus = () => {
        setshowloader(true)
        const dataString = {
            scenerio_status: 1,
            scenerio_id: sceneriores._id,
            property_id: sceneriores.property_id
        }
        ApiService.postData('/property/changesceneriostatus', dataString).then((res) => {
            if (res.status == 'success') {
                setshowloader(false)
                toast.success('Scenerio has been saved successfully')
                setTimeout(() => {
                    navigate('/my-property')
                }, 1000)
            } else if (res?.status == "error" && (res.message == 'session_expired' || res.message === 'User not found')) {
                toast.error(res?.data?.message)
                localStorage.removeItem("USER_TOKEN")
                setshowloader(false)
                navigate('/')
            }
            else if (res?.status === "error") {
                toast.error(res?.data?.message);
                setshowloader(false);
            }
        })
    }
    const deleteScenerio = async () => {
        const dataString = {
            scenerio_id: sceneriores._id,
            property_id: sceneriores.property_id
        }
        ApiService.postData('/property/deletescenerio', dataString).then((res) => {
            if (res?.status == "success") {
                toast.success(res.message)
                setTimeout(() => {
                    setInflation([1])
                    setrentalGrowth([1])
                    setgrowthAsuump([1])
                    setselectYear('')
                    setShow(false)
                    setshowsceneriotable(false)
                    setshowsubmit(false)
                }, 500)
            } else if (res?.status == "error" && res.message == 'session_expired') {
                toast.error(res?.data?.message)
                localStorage.removeItem("USER_TOKEN")
                setshowloader(false)
                navigate('/')
            }
            else {
                setShow(false)
                toast.error(res?.message)
            }
        }).catch((error) => { setShow(false) })
    }
    const onreject = () => {
        setShow(false)
    }
    const handleChangeInput = (e, index) => {
        const { name, value } = e.target;
        setshowinputs((prevInputs) => {
            const updatedInputs = { ...prevInputs };

            updatedInputs[index] = {
                ...updatedInputs[index],
                [name]: value,
            };
            updatedInputs[index] = {
                ...updatedInputs[index],
                estimate_heading: selectedValues[index],
            };
            return updatedInputs;
        });

    };
    const submitMainatianancedata = (index) => {
        const dataString = {
            scenerio_id: sceneriores?._id,
            property_id: id
        };

        ApiService.postData('/property/getsceneriodetail', dataString).then((res) => {
            if (res.status === "success") {
                const data = res.scenerodetail?.scenerio_table_data;
                const originalArray = [...data];
                const updatedMaintainanceData = [...scenerioTableData];
                const updatedItem = updatedMaintainanceData[index];
                const objectsDiffer = Object.keys(originalArray[index]).some((key) => {
                    return originalArray[index][key] !== updatedItem[key];
                });
                if (objectsDiffer) {
                    originalArray[index] = { ...updatedItem };
                    const dataString = {
                        scenerio_table_data: originalArray,
                        property_id: id,
                        scenerio_id: sceneriores?._id,
                        year: sceneriores.scenerio_year
                    };
                    ApiService.postData('/property/updatesceneriodetail', dataString).then((res) => {
                        if (res?.status == "success") {
                            toast.success(res.message)

                        }
                        else if (res?.status == "error" && res.message == 'session_expired') {
                            toast.error(res?.message)
                            localStorage.removeItem("USER_TOKEN")
                            setshowloader(false)
                            navigate('/')
                        }
                        else if (res?.status === "error") {
                            toast.error(res?.message);
                            setshowloader(false);
                        }

                    });
                    setshowinputs((prevInputs) => ({
                        ...prevInputs,
                        [index]: {
                            inflation: "",
                            rental_growth: "",
                            growth_assump: "",
                            owner_occupied_week: "",
                            letting_fee_weeks: "",
                            vacant_week: "",
                            building_insuar: "",
                            land_tax: "",
                            property_manag_perc: "",
                        },
                    }));
                    setshowactionbtns((prevShowActionBtns) => ({
                        ...prevShowActionBtns,
                        [index]: false,
                    }));
                    return;
                }
            }
            else if (res?.status == "error" && res.message == 'session_expired') {
                toast.error(res?.message)
                localStorage.removeItem("USER_TOKEN")
                setshowloader(false)
                navigate('/')
            }
            else if (res?.status === "error") {
                toast.error(res?.message);
                setshowloader(false);
            }
        });
    };
    const handleEditClick = (index) => {
        const selectedObject = scenerioTableData[index];
        setselectObject(selectedObject);
        setSelectedRowIndex(index);
        // seteditclickstate(true)
        ChangescenerioModal();
    };
    const handleInputEditSubmit = (index) => {
        const updatedMaintainanceData = [...scenerioTableData];
        const selectedItem = updatedMaintainanceData[index];
        const rowInputs = showinputs[index] || {};
        const valuesChanged = Object.keys(rowInputs).some((key) => {
            return rowInputs[key] !== selectedObject[key];
        });
        selectedItem.estimate_heading = valuesChanged ? "1" : selectedObject.estimate_heading;
        selectedItem.weekly_rental_income = parseFloat(rowInputs.weekly_rental_income) ? parseFloat(rowInputs.weekly_rental_income) : parseFloat(rowInputs.weekly_rental_income) == 0 ? 0 : selectedItem.weekly_rental_income;
        selectedItem.vacant_week = parseFloat(rowInputs.vacant_week) ? parseFloat(rowInputs.vacant_week) : parseFloat(rowInputs.vacant_week) == 0 ? 0 : selectedItem.vacant_week;
        selectedItem.letting_fee_weeks = parseFloat(rowInputs.letting_fee_weeks) ? parseFloat(rowInputs.letting_fee_weeks) : parseFloat(rowInputs.letting_fee_weeks) == 0 ? 0 : selectedItem.letting_fee_weeks;
        selectedItem.owner_occupied_week = parseFloat(rowInputs.owner_occupied_week) ? parseFloat(rowInputs.owner_occupied_week) : parseFloat(rowInputs.owner_occupied_week) == 0 ? 0 : selectedItem.owner_occupied_week;
        selectedItem.inflation = parseFloat(rowInputs.inflation) ? parseFloat(rowInputs.inflation) : parseFloat(rowInputs.inflation) == 0 ? 0 : selectedItem.inflation;
        selectedItem.growth_assump = parseFloat(rowInputs.growth_assump) ? parseFloat(rowInputs.growth_assump) : parseFloat(rowInputs.growth_assump) == 0 ? 0 : selectedItem.growth_assump;
        selectedItem.rental_growth = parseFloat(rowInputs.rental_growth) ? parseFloat(rowInputs.rental_growth) : parseFloat(rowInputs.rental_growth) == 0 ? 0 : selectedItem.rental_growth;
        selectedItem.property_manag_perc = parseFloat(rowInputs.property_manag_perc) ? parseFloat(rowInputs.property_manag_perc) : parseFloat(rowInputs.property_manag_perc) == 0 ? 0 : selectedItem.property_manag_perc;
        selectedItem.land_tax = parseFloat(rowInputs.land_tax) ? parseFloat(rowInputs.land_tax) : parseFloat(rowInputs.land_tax) == 0 ? 0 : selectedItem.land_tax;
        selectedItem.building_insuar = parseFloat(rowInputs.building_insuar) ? parseFloat(rowInputs.building_insuar) : parseFloat(rowInputs.building_insuar) == 0 ? 0 : selectedItem.building_insuar;
        const updatedselecteditem = performAdditionalCalculations(selectedItem, index, rowInputs, scenerioTableData);
        // Update the selectedItem with the updated values
        for (const key in updatedselecteditem) {
            selectedItem[key] = updatedselecteditem[key];
        }
        setScenerioTableData(updatedMaintainanceData);
        setshowinputs((prevInputs) => ({
            ...prevInputs,
            [index]: {
                inflation: "",
                rental_growth: "",
                growth_assump: "",
                owner_occupied_week: "",
                letting_fee_weeks: "",
                vacant_week: "",
                building_insuar: "",
                land_tax: "",
                property_manag_perc: "",
            },
        }));
        setshowactionbtns((prevShowActionBtns) => ({
            ...prevShowActionBtns,
            [index]: true,
        }));
        ChangescenerioModal()
    };
    const performAdditionalCalculations = (selectedItem, index, rowInputs, scenerioTableData) => {
        // additional calculation start
        let weekly_rental_income = 0
        let Council_rates = 0
        let water_rates = 0
        let building_insuar = 0
        let maintaince_allownce = 0
        let property_value = 0
        let principal_payment_fixed = 0
        let principal_payment_variable = 0
        let principal_pay = 0
        let total_loan_amount = 0
        let loanamount_variable = 0
        let loanamount_fixed = 0
        let totalLoan_interest_fixed = 0
        let totalLoan_interest_variable = 0
        let principal_payment_variable_cummulative = 0
        let previous_net_annualcashflow = 0
        let previous_property_value = 0
        let previousprincipal_vari = 0
        let previousprincipal_fix = 0
        let principal_payment_fixed_cummulative = 0
        let previous_capital_commuta_growth_value = 0
        let previous_net_annualcashflowcumu = 0
        const currentYear = selectedItem?.currentYear;
        const estimate_heading = selectedItem?.estimate_heading
        const growth_assump = selectedItem?.growth_assump
        const rental_growth = selectedItem?.rental_growth
        const inflation = selectedItem?.inflation
        const Loan_interest_fixed = selectedItem?.Loan_interest_fixed
        const Loan_interest_variable = selectedItem?.Loan_interest_variable
        const termLength = 30
        const rf = Loan_interest_fixed / 100
        const rv = Loan_interest_variable / 100
        const purchase_fixedperc = parseFloat(purchasedata?.purchase_interest_fixed) / 100
        const purchase_variableperc = parseFloat(purchasedata?.purchase_interest_variable) / 100
        const totalrequiredcash = parseFloat(purchasedata?.purchase_total_cash_req)
        if (rowInputs.hasOwnProperty('weekly_rental_income') && typeof rowInputs.weekly_rental_income !== 'undefined') {
            weekly_rental_income = parseFloat(rowInputs.weekly_rental_income) || 0;
        } else if (index > 0) {
            const previousIndex = index - 1 >= 0 ? index - 1 : scenerioTableData.length - 1;
            const previousWeeklyRentalIncome = parseFloat(scenerioTableData[previousIndex]?.weekly_rental_income) || 0;
            weekly_rental_income = previousWeeklyRentalIncome + (previousWeeklyRentalIncome * (selectedItem?.rental_growth / 100));
        }
        else {
            weekly_rental_income = parseFloat(selectedItem?.weekly_rental_income)
        }
        if (rowInputs.hasOwnProperty('building_insuar') && typeof rowInputs.building_insuar !== 'undefined') {
            building_insuar = parseFloat(rowInputs.building_insuar) || 0;
        } else if (index > 0) {
            const previousIndex = index - 1 >= 0 ? index - 1 : scenerioTableData.length - 1;
            const previousbuildins = parseFloat(scenerioTableData[previousIndex]?.building_insuar) || 0;
            building_insuar = previousbuildins + (previousbuildins * (selectedItem?.inflation / 100));
        }
        else {
            building_insuar = parseFloat(selectedItem?.building_insuar)
        }
        if (index > 0) {
            const previousIndex = index - 1 >= 0 ? index - 1 : scenerioTableData.length - 1;
            const previouspropvalue = parseFloat(scenerioTableData[previousIndex]?.property_value) || 0;
            const previouswater_rate = parseFloat(scenerioTableData[previousIndex]?.water_rates) || 0;
            const previouscouncilrate = parseFloat(scenerioTableData[previousIndex]?.Council_rates) || 0;
            const previousmaintainallow = parseFloat(scenerioTableData[previousIndex]?.maintaince_allownce) || 0;
            const previousloanamount_fixed = parseFloat(scenerioTableData[previousIndex]?.loanamount_fixed) || 0;
            const previousloanamount_varia = parseFloat(scenerioTableData[previousIndex]?.loanamount_variable) || 0;
            const previoustotalloanamount = parseFloat(scenerioTableData[previousIndex]?.total_loan_amount) || 0;
            previousprincipal_vari = parseFloat(scenerioTableData[previousIndex]?.principal_payment_variable) || 0;
            previousprincipal_fix = parseFloat(scenerioTableData[previousIndex]?.principal_payment_fixed) || 0;
            const previousprincipal_pay = parseFloat(scenerioTableData[previousIndex]?.principal_pay) || 0;
            previous_net_annualcashflowcumu = parseFloat(scenerioTableData[previousIndex]?.net_annual_cashflow_cummulative)
            previous_capital_commuta_growth_value = parseFloat(scenerioTableData[previousIndex]?.capital_growth_annual_cummulative)
            previous_net_annualcashflow = parseFloat(scenerioTableData[previousIndex]?.net_annual_cash_flow)
            property_value = previouspropvalue + (previouspropvalue * (selectedItem?.growth_assump / 100));
            water_rates = previouswater_rate + (previouswater_rate * (selectedItem.inflation / 100));
            Council_rates = previouscouncilrate + (previouscouncilrate * (selectedItem.inflation / 100));
            maintaince_allownce = previousmaintainallow + (previousmaintainallow * (selectedItem.inflation / 100));
            loanamount_variable = previousloanamount_varia - previousprincipal_vari
            loanamount_fixed = previousloanamount_fixed - previousprincipal_fix
            total_loan_amount = previoustotalloanamount - previousprincipal_pay
        }
        else {
            water_rates = selectedItem?.water_rates
            Council_rates = selectedItem?.Council_rates
            maintaince_allownce = selectedItem?.maintaince_allownce
            property_value = selectedItem?.property_value
            total_loan_amount = selectedItem?.total_loan_amount
            loanamount_fixed = parseFloat(total_loan_amount * purchase_fixedperc)
            loanamount_variable = parseFloat(total_loan_amount * purchase_variableperc)
            previous_net_annualcashflowcumu = 0
            previousprincipal_vari = 0
            previousprincipal_fix = 0
            previous_capital_commuta_growth_value = 0
            previous_net_annualcashflow = 0

        }
        totalLoan_interest_variable = total_loan_amount * purchase_variableperc * rv
        totalLoan_interest_fixed = total_loan_amount * purchase_fixedperc * rf
        let fixedmonthlypayment = () => {
            const totalfixedLoanAmount = parseFloat(loanamount_fixed);
            const interestRate = parseFloat(Loan_interest_fixed) / 1200;
            const numberOfMonths = parseFloat(termLength) * 12;

            if (
                !isNaN(totalfixedLoanAmount) &&
                !isNaN(interestRate) &&
                !isNaN(numberOfMonths)
            ) {
                const result = ((totalfixedLoanAmount *
                    interestRate *
                    Math.pow(1 + interestRate, numberOfMonths)) /
                    (Math.pow(1 + interestRate, numberOfMonths) - 1)).toFixed(2)
                return result;
            } else {
                // Handle invalid inputs or show an error message
                return "Invalid input. Please check your values.";
            }
        };

        let variablemonthlypayment = () => {
            const totalvariableLoanAmount = parseFloat(loanamount_variable);
            const interestRate = parseFloat(Loan_interest_variable) / 1200;
            const numberOfMonths = parseFloat(termLength) * 12;

            if (
                !isNaN(totalvariableLoanAmount) &&
                !isNaN(interestRate) &&
                !isNaN(numberOfMonths)
            ) {
                const result = ((totalvariableLoanAmount *
                    interestRate *
                    Math.pow(1 + interestRate, numberOfMonths)) /
                    (Math.pow(1 + interestRate, numberOfMonths) - 1)).toFixed(2)
                return result;
            } else {
                // Handle invalid inputs or show an error message
                return "Invalid input. Please check your values.";
            }
        };
        principal_payment_fixed = ((fixedmonthlypayment() * 12) - (totalLoan_interest_fixed))
        principal_payment_variable = ((variablemonthlypayment() * 12) - (totalLoan_interest_variable))
        principal_pay = parseFloat(principal_payment_variable) + parseFloat(principal_payment_fixed)
        const purchase_loan_amount = parseFloat(selectedItem?.purchase_loan_amount)
        const total_loan_interest = totalLoan_interest_fixed + totalLoan_interest_variable
        const purchase_building_insua_cost = parseFloat(selectedItem?.purchase_building_insua_cost)
        const purchase_water_rates = parseFloat(selectedItem?.purchase_water_rates)
        const purchase_council_rates = parseFloat(selectedItem?.purchase_council_rates)
        const property_purchase_price = parseFloat(selectedItem?.property_purchase_price)
        const purchase_allownce_main = parseFloat(selectedItem?.purchase_allownce_main)
        const gross_annual_rental = weekly_rental_income * 52
        const owner_occupied_week = selectedItem?.owner_occupied_week
        const owner_rental_saved = weekly_rental_income * owner_occupied_week
        const vacant_week = selectedItem?.vacant_week
        const vacant_rate = vacant_week / 52
        const rental_loss = weekly_rental_income * vacant_week
        const letting_fee_weeks = selectedItem?.letting_fee_weeks
        const actual_rental_inc = (gross_annual_rental * (1 - vacant_rate)) - (letting_fee_weeks) + (owner_rental_saved)
        const letting_fee = weekly_rental_income * letting_fee_weeks
        const property_manag_perc = selectedItem?.property_manag_perc
        const total_property_manage_fee = gross_annual_rental * (property_manag_perc / 100)
        const interest_fixed = selectedItem?.interest_fixed
        const interest_variable = selectedItem?.interest_variable
        const adjusted_income = gross_annual_rental + owner_rental_saved - (rental_loss + letting_fee)
        const gross_annual_property_income = adjusted_income
        const forcast_annual_cash_exp = parseFloat(total_property_manage_fee) + parseFloat(building_insuar) + parseFloat(water_rates) + parseFloat(Council_rates) + parseFloat(maintaince_allownce) + parseFloat(total_loan_interest)
        const land_tax = selectedItem?.land_tax
        const forcast_annual_non_deductexpe = parseFloat(land_tax) + parseFloat(principal_pay)
        const potential_deduction = parseFloat(forcast_annual_cash_exp) + parseFloat(interest_fixed) + parseFloat(interest_variable)
        const forcast_annual_deduc = selectedItem?.forcast_annual_deduc
        const potential_annual_net_cash_flow = parseFloat(forcast_annual_non_deductexpe) + parseFloat(potential_deduction)
        const equity = parseFloat(property_value) - parseFloat(total_loan_amount)
        // new added fields
        const interest_expense_fixed = parseFloat(totalLoan_interest_fixed)
        const interest_expense_variable = parseFloat(totalLoan_interest_variable)
        const total_interest_expence = parseFloat(interest_expense_fixed) + parseFloat(interest_expense_variable)
        let total_uplift_cost = 0
        const operating_expenses = parseFloat(total_property_manage_fee) + parseFloat(building_insuar) + parseFloat(water_rates) + parseFloat(Council_rates) + parseFloat(maintaince_allownce)
        const cashoutflow = parseFloat(total_interest_expence) + parseFloat(operating_expenses) + parseFloat(total_uplift_cost)
        const gross_income = parseFloat(adjusted_income)
        const total_principal_payment = parseFloat(principal_payment_fixed) + parseFloat(principal_payment_variable)
        const net_annual_cash_flow = parseFloat(gross_income) - parseFloat(cashoutflow) - parseFloat(total_principal_payment)

        const net_annual_cashflow_cummulative = parseFloat(net_annual_cash_flow) + parseFloat(previous_net_annualcashflowcumu)
        principal_payment_fixed_cummulative = parseFloat(principal_payment_fixed) + parseFloat(previousprincipal_fix)
        principal_payment_variable_cummulative = parseFloat(principal_payment_variable) + parseFloat(previousprincipal_vari)

        const totalprincipalpaymentcummulative = parseFloat(principal_payment_fixed_cummulative) + parseFloat(principal_payment_variable_cummulative)
        const grossyield = (parseFloat(gross_annual_rental) / parseFloat(property_purchase_price)) * 100
        const netyeild = (parseFloat(net_annual_cash_flow) / parseFloat(property_purchase_price)) * 100
        const income_per_month = parseFloat(net_annual_cash_flow) / 12
        const income_per_week = parseFloat(net_annual_cash_flow) / 52
        const capital_growth_annual = parseFloat(property_value) - parseFloat(previous_property_value)
        const capital_growth_annual_cummulative = parseFloat(capital_growth_annual) + parseFloat(previous_capital_commuta_growth_value)
        const total_performance = parseFloat(capital_growth_annual_cummulative) + parseFloat(net_annual_cashflow_cummulative)
        const total_performance_principal = parseFloat(capital_growth_annual_cummulative) + parseFloat(net_annual_cash_flow) + parseFloat(totalprincipalpaymentcummulative)
        const cash_on_cash_return = ((parseFloat(net_annual_cash_flow) + parseFloat(previous_net_annualcashflow)) / parseFloat(totalrequiredcash)) * 100
        const return_interest_capital = ((parseFloat(equity) + parseFloat(net_annual_cash_flow)) / parseFloat(totalrequiredcash)) * 100
        const updatedselecteditem = {
            estimate_heading: estimate_heading,
            currentYear: currentYear,
            growth_assump: growth_assump,
            rental_growth: rental_growth,
            inflation: inflation,
            weekly_rental_income: weekly_rental_income,
            purchase_building_insua_cost: purchase_building_insua_cost,
            purchase_water_rates: purchase_water_rates,
            purchase_council_rates: purchase_council_rates,
            purchase_loan_amount: purchase_loan_amount,
            property_purchase_price: property_purchase_price,
            purchase_allownce_main: purchase_allownce_main,
            gross_annual_rental: gross_annual_rental,
            owner_occupied_week: owner_occupied_week,
            owner_rental_saved: owner_rental_saved,
            vacant_week: vacant_week,
            vacant_rate: vacant_rate,
            rental_loss: rental_loss,
            adjusted_income: adjusted_income,
            totalLoan_interest_fixed: totalLoan_interest_fixed,
            totalLoan_interest_variable: totalLoan_interest_variable,
            total_loan_interest: total_loan_interest,
            gross_annual_property_income: gross_annual_property_income,
            letting_fee_weeks: letting_fee_weeks,
            letting_fee: letting_fee,
            actual_rental_inc: actual_rental_inc,
            property_manag_perc: property_manag_perc,
            total_property_manage_fee: total_property_manage_fee,
            interest_fixed: interest_fixed,
            interest_variable: interest_variable,
            building_insuar: building_insuar,
            Loan_interest_fixed: Loan_interest_fixed,
            Loan_interest_variable: Loan_interest_variable,
            water_rates: water_rates,
            Council_rates: Council_rates,
            maintaince_allownce: maintaince_allownce,
            forcast_annual_cash_exp: forcast_annual_cash_exp,
            land_tax: land_tax,
            principal_pay: principal_pay,
            forcast_annual_non_deductexpe: forcast_annual_non_deductexpe,
            forcast_annual_deduc: forcast_annual_deduc,
            potential_annual_net_cash_flow: potential_annual_net_cash_flow,
            property_value: property_value,
            principal_payment_fixed: principal_payment_fixed,
            total_loan_amount: total_loan_amount,
            equity: equity,
            maintainance_property_id: id,
            // new added fields
            cashoutflow: cashoutflow,
            total_interest_expence: total_interest_expence,
            interest_expense_variable: interest_expense_variable,
            interest_expense_fixed: interest_expense_fixed,
            principal_payment_variable_cummulative: principal_payment_variable_cummulative,
            principal_payment_fixed_cummulative: principal_payment_fixed_cummulative,
            principal_payment_variable: principal_payment_variable,
            capital_growth_annual_cummulative: capital_growth_annual_cummulative,
            capital_growth_annual: capital_growth_annual,
            net_annual_cashflow_cummulative: net_annual_cashflow_cummulative,
            net_annual_cash_flow: net_annual_cash_flow,
            total_uplift_cost: total_uplift_cost,
            total_performance_principal: total_performance_principal,
            total_performance: total_performance,
            income_per_week: income_per_week,
            income_per_month: income_per_month,
            netyeild: netyeild,
            grossyield: grossyield,
            totalprincipalpaymentcummulative: totalprincipalpaymentcummulative,
            total_principal_payment: total_principal_payment,
            loanamount_variable: loanamount_variable,
            loanamount_fixed: loanamount_fixed,
            gross_income: gross_income,
            operating_expenses: operating_expenses,
            cash_on_cash_return: cash_on_cash_return,
            return_interest_capital: return_interest_capital
        }
        return updatedselecteditem;
        // additional calculation end
    };

    const handleResetChanges = (index) => {
        const dataString = {
            scenerio_id: sceneriores?._id,
            property_id: id
        };

        ApiService.postData('/property/getsceneriodetail', dataString).then((res) => {
            if (res.status === "success") {
                const data = res.scenerodetail?.scenerio_table_data;
                const updatedMaintainanceData = [...scenerioTableData];
                const selectedItem = updatedMaintainanceData[index];
                updatedMaintainanceData[index] = { ...data[index] };
                setScenerioTableData(updatedMaintainanceData);
                setshowinputs((prevInputs) => ({
                    ...prevInputs,
                    [index]: {
                        inflation: "",
                        rental_growth: "",
                        growth_assump: "",
                        owner_occupied_week: "",
                        letting_fee_weeks: "",
                        vacant_week: "",
                        building_insuar: "",
                        land_tax: "",
                        property_manag_perc: "",
                    },
                }));
                setshowactionbtns((prevShowActionBtns) => ({
                    ...prevShowActionBtns,
                    [index]: false,
                }));

                return;
            } else if (res?.data?.status == "error" && res.data.message == 'session_expired') {
                toast.error(res?.data?.message)
                localStorage.removeItem("USER_TOKEN")
                setshowloader(false)
                navigate('/')
            }
            else if (res?.data?.status === "error") {
                toast.error(res?.data?.message);
                setshowloader(false);
            }
        });
    };
    const ChangescenerioModal = () => {
        contextValues.setToggleChangeScenerio(!contextValues.toggleChnageScenerio)
    }


    return (<>
        <Header></Header>
        <section className="section-gap-md">
            <div className="container">
                {/* <div>
               <h5 className="text-center">Input your scenario.
               </h5>
               </div> */}
                <div className="row">

                    <div className="col-2">
                        <Range
                            step={0.2}
                            min={1}
                            max={10}
                            values={inflation}
                            onChange={(values) => { setInflation(values); setshowsubmit(true) }}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "6px",
                                        width: "100%",
                                        backgroundColor: "rgb(218 160 40)",
                                        position: "relative",
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props, value }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "22px",
                                        width: "22px",
                                        backgroundColor: "rgb(102 44 94)",
                                        borderRadius: "50%",
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* Label/Tooltip */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "-37px",
                                            color: "#fff",
                                            backgroundColor: "rgb(102 44 94)",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            fontSize: "12px",
                                            whiteSpace: "nowrap",

                                        }}
                                    >
                                        {value.toFixed(1)} {/* Display with one decimal */}
                                    </div>
                                </div>
                            )}
                        />
                        <p className="mt-2">Inflation: {inflation[0].toFixed(1)}</p>

                        <p className="text-center mt-2 fw-bold">Set the value of Inflation</p>
                    </div>
                    <div className="col-2">
                        <Range
                            step={0.2}
                            min={1}
                            max={50}
                            values={growthAsuump}
                            onChange={(values) => { setgrowthAsuump(values); setshowsubmit(true) }}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "6px",
                                        width: "100%",
                                        backgroundColor: "rgb(218 160 40)",
                                        position: "relative",
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props, value }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "22px",
                                        width: "22px",
                                        backgroundColor: "rgb(102 44 94)",
                                        borderRadius: "50%",
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* Label/Tooltip */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "-37px",
                                            color: "#fff",
                                            backgroundColor: "rgb(102 44 94)",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            fontSize: "12px",
                                            whiteSpace: "nowrap",

                                        }}
                                    >
                                        {value.toFixed(1)} {/* Display with one decimal */}
                                    </div>
                                </div>
                            )}
                        />
                        <p className="mt-2">Growth Asumption: {inflation[0].toFixed(1)}</p>
                        <p className="text-center mt-2 fw-bold">Set the value of Growth Assumption</p>
                    </div>
                    <div className="col-2">
                        <Range
                            step={0.2}
                            min={1}
                            max={50}
                            values={rentalgrowth}
                            onChange={(values) => { setrentalGrowth(values); setshowsubmit(true) }}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "6px",
                                        width: "100%",
                                        backgroundColor: "rgb(218 160 40)",
                                        position: "relative",
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props, value }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "22px",
                                        width: "22px",
                                        backgroundColor: "rgb(102 44 94)",
                                        borderRadius: "50%",
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* Label/Tooltip */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "-37px",
                                            color: "#fff",
                                            backgroundColor: "rgb(102 44 94)",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            fontSize: "12px",
                                            whiteSpace: "nowrap",

                                        }}
                                    >
                                        {value.toFixed(1)} {/* Display with one decimal */}
                                    </div>
                                </div>
                            )}
                        />
                        <p className="mt-2">Rental Growth: {inflation[0].toFixed(1)}</p>
                        <p className="text-center mt-2 fw-bold">Set the value of Rental Growth</p>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                        <label>How many years should the override scenario apply?</label>
                        <input value={selectYear} onChange={(e) => { setselectYear(e.target.value); setshowsubmit(true) }}></input>

                        </div>
                       
                    </div>
                    {showsubmit && (
                        <div className="col-3">
                            <button
                                className="btn btn-primary btn-md"
                                type="button"
                                name="submit"
                                disabled={showloader}
                                onClick={(e) => {
                                    handleSubmit(e)
                                }}

                            >
                                {showloader ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                ) : (
                                    " Submit"
                                )}
                            </button>

                        </div>
                    )}
                    {showsceneriotable && (
                        <div className="col-12">
                            <div className="d-flex justify-content-evenly mt-5 ">
                            <p><span className="text-danger">Note: </span>Please don't refresh the page as you refresh the page you lost the changes you made to create scenerio.</p>
                                <div>
                                    <button onClick={() => { changeStatus() }} type="button" className="btn btn-primary" disabled={showloader}> {showloader ? (
                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                    ) : (
                                        "Save"
                                    )}</button>
                                    <button onClick={() => { setShow(true) }} type="button" className="btn btn-primary ms-4" disabled={showloader}> {showloader ? (
                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                    ) : (
                                        "Discard"
                                    )}</button>

                                </div>
                               
                            </div>

                            {scenerioTableData && scenerioTableData?.slice(0, sceneriores.scenerio_year).length > 0 && <>
                                <h6 className="text-center">Scnerio according to the {sceneriores.scenerio_year} years  </h6>
                                <div style={{ overflowX: 'auto', width: '100%' }} className="mt-5"  >
                                    <div>
                                        <Nav fill variant="tabs" defaultActiveKey={showtable}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-0" onClick={(e) => { setshowtable("detailedassumption") }}> Detailed Assumption</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-1" onClick={(e) => { setshowtable("incomeoveryear") }}>Income Over Year</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-3" onClick={(e) => { setshowtable("expenses") }}>Expenses</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-4" onClick={(e) => { setshowtable("annalysis") }}>Annalysis</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-5" onClick={(e) => { setshowtable("summary") }}>Summary</Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                    </div>
                                    {showtable == "detailedassumption" &&
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>year</th>
                                                    <th>Estimate/Actual</th>
                                                    <th>Growth Assumption</th>
                                                    <th>Rental Growth</th>
                                                    <th>Inflation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {scenerioTableData?.slice(0, sceneriores.scenerio_year)?.map((item, index) => {
                                                    return (<>
                                                        <tr key={index}>
                                                            <td>{item.currentYear}</td>
                                                            <td>

                                                                {item.estimate_heading == "0" ? "Estimate" : item?.estimate_heading == "1" ? "Actual" : ""}
                                                            </td>
                                                            <td>
                                                                {item.growth_assump + "%"}
                                                            </td>
                                                            <td>
                                                                {item.rental_growth + "%"}
                                                            </td>
                                                            <td>
                                                                {item.inflation + "%"}
                                                            </td>
                                                            <td>
                                                                <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index) }}></i>
                                                            </td>
                                                            {showactionbtns[index] ? <>

                                                                <td>
                                                                    <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index) }}></i>

                                                                </td>
                                                                <td>

                                                                    <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index)}></i>
                                                                </td>
                                                            </> : ''}

                                                        </tr>


                                                    </>)
                                                })}


                                            </tbody >
                                        </Table>}
                                    {showtable == "incomeoveryear" && <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>year</th>
                                                <th>Weekly rental income</th>
                                                <th>Gross Annual Rental Income</th>
                                                <th>Weeks Vacant</th>
                                                <th>Vacancy Rate(weeks/52)</th>
                                                <th>Rental Loss</th>
                                                {/* <th>Actual Rental Income</th> */}
                                                <th>Letting Fee(weeks)</th>
                                                <th>Letting Fee($)</th>
                                                <th>Weeks Owner Occupied</th>
                                                <th>Owner Occupied Rent Saved</th>
                                                <th>Adjusted Income</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {scenerioTableData?.slice(0, sceneriores.scenerio_year)?.map((items, index) => {
                                                return (<>
                                                    <tr key={index}>
                                                        <td>{items?.currentYear}</td>
                                                        <td>
                                                        {contextValues.Currency} {(items?.weekly_rental_income)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.gross_annual_rental)?.toFixed(3)}</td>
                                                        <td>
                                                            {(items?.vacant_week)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.vacant_rate)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.rental_loss)?.toFixed(3)}</td>
                                                        {/* <td>{(items?.actual_rental_inc).toFixed(3)}</td> */}
                                                        <td>
                                                        {contextValues.Currency} {(items?.letting_fee_weeks)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.letting_fee)?.toFixed(3)}</td>
                                                        <td>
                                                            {(items?.owner_occupied_week)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.owner_rental_saved)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.adjusted_income)?.toFixed(3)}</td>
                                                        <td>
                                                            <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index) }}></i>
                                                        </td>
                                                        {showactionbtns[index] ? <>

                                                            <td>
                                                                <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index) }}></i>

                                                            </td>
                                                            <td>

                                                                <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index)}></i>
                                                            </td>
                                                        </> : ''}
                                                    </tr>


                                                </>)
                                            })}


                                        </tbody>
                                    </Table>}
                                    {showtable == "expenses" && <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>year</th>
                                                <th>Total Loan Amount </th>
                                                <th>Fixed Loan Amount </th>
                                                <th>Variable Loan Amount </th>
                                                <th>Loan Interest Fixed</th>
                                                <th>Loan Interest Variable</th>
                                                <th>Total Loan Interest Fixed</th>
                                                <th>Total Loan Interest Variable</th>
                                                <th>Total Loan Interest </th>
                                                <th>Property Manangment % </th>
                                                <th> Total Property Manangment</th>
                                                <th> building Landloard Insurance</th>
                                                <th>Water Rates</th>
                                                <th>Council Rates</th>
                                                <th>Maintainance</th>
                                                <th>Forcast annual cash expense</th>
                                                <th>Land Tax (if applicable)</th>
                                                <th>Principal Payments Fixed</th>
                                                <th>Principal Payments Variable</th>
                                                <th>Principal Payments</th>
                                                <th>Forcast Annual Non-deductable Expenses</th>

                                                <th>Potential annual net cash outflow</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {scenerioTableData?.slice(0, sceneriores.scenerio_year)?.map((items, index) => {

                                                return (<>
                                                    <tr key={index}>
                                                        <td>{items?.currentYear}</td>
                                                        <td>{contextValues.Currency}{(items?.total_loan_amount)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.loanamount_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.loanamount_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{items?.Loan_interest_fixed}</td>
                                                        <td>{contextValues.Currency}{items?.Loan_interest_variable}</td>
                                                        <td>{contextValues.Currency}{(items?.totalLoan_interest_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.totalLoan_interest_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_loan_interest)?.toFixed(3)}</td>
                                                        <td>{(items?.property_manag_perc)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_property_manage_fee)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.building_insuar)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.water_rates)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.Council_rates)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.maintaince_allownce)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.forcast_annual_cash_exp)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.land_tax)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_pay)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.forcast_annual_non_deductexpe)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.potential_annual_net_cash_outflow)?.toFixed(3)}</td>
                                                        <td>
                                                            <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index) }}></i>
                                                        </td>
                                                        {showactionbtns[index] ? <>

                                                            <td>
                                                                <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index) }}></i>

                                                            </td>
                                                            <td>

                                                                <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index)}></i>
                                                            </td>
                                                        </> : ''}
                                                    </tr>
                                                </>)
                                            })}
                                        </tbody>
                                    </Table>}
                                    {showtable == "annalysis" && <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>year</th>
                                                <th>Property Value</th>
                                                {/* <th>Loan Amount Fixed</th>
                                                <th>Loan Amount Variable</th>
                                                <th>Total Loan Amount</th> */}
                                                <th>Equity</th>
                                                {/* <th>Principal Payment Fixed</th>
                                                <th>Principal Payment Cummulative Fixed</th>
                                                <th>Principal Payment Variable</th>
                                                <th>Principal Payment Cummulative Variable</th>
                                                <th>Total Principal Payment</th>
                                                <th>Total Principal Payment Cummulative</th>
                                                <th>Interest Rate Fixed</th>
                                                <th>Interest Rate Variable</th> */}
                                                <th>Net Adjusted income</th>
                                                <th>Gross Yield %</th>
                                                <th>Cash Outflows</th>
                                                <th>Interest Expenses Fixed</th>
                                                <th>Interest Expenses Variable</th>
                                                <th>Total Interest Expenses</th>
                                                <th>Operating Expenses</th>
                                                <th>Net Annualcashflow</th>
                                                <th>Net Annualcashflow Cummulative</th>
                                                <th>Net Yield %</th>
                                                <th>Capital Annual Growth</th>
                                                <th>Capital Cummulative Growth</th>
                                                <th>Total Performance</th>
                                                <th>Total Performance Including Principal</th>
                                                <th>Cash On Cash Returns %</th>
                                                <th>Return On Invested Capital %</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {scenerioTableData?.slice(0, selectYear)?.map((items, index) => {
                                                return (<>
                                                    <tr key={index}>
                                                        <td>{items?.currentYear}</td>
                                                        <td>{contextValues.Currency}{(items?.property_value)?.toFixed(3)}</td>
                                                        {/* <td>{contextValues.Currency}{(items?.loanamount_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.loanamount_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_loan_amount).toFixed(3)}</td> */}
                                                        <td>{contextValues.Currency}{(items?.equity)?.toFixed(3)}</td>
                                                        {/* <td>{contextValues.Currency}{(items?.principal_payment_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_fixed_cummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_variable_cummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_principal_payment)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.totalprincipalpaymentcummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.Loan_interest_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.Loan_interest_variable)?.toFixed(3)}</td> */}
                                                        <td>{contextValues.Currency}{(items?.gross_income)?.toFixed(3)}</td>
                                                        <td>{(items?.grossyield)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.cashoutflow)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.interest_expense_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.interest_expense_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_interest_expence)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.operating_expenses)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.net_annual_cash_flow)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.net_annual_cashflow_cummulative)?.toFixed(3)}</td>
                                                        <td>{(items?.netyeild)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.capital_growth_annual)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.capital_growth_annual_cummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_performance)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_performance_principal)?.toFixed(3)}</td>
                                                        <td>{(items?.cash_on_cash_return)?.toFixed(3)}</td>
                                                        <td>{(items?.return_interest_capital)?.toFixed(3)}</td>
                                                        <td>
                                                            <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index) }}></i>
                                                        </td>
                                                        {showactionbtns[index] ? <>
                                                            <td>
                                                                <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index) }}></i>
                                                            </td>
                                                            <td>
                                                                <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index)}></i>
                                                            </td>
                                                        </> : ''}
                                                    </tr>
                                                </>)
                                            })}
                                        </tbody>
                                    </Table>}
                                    {showtable == 'summary' &&
                                        <ScenerioSummary scenerioTable={scenerioTableData?.slice(0, sceneriores.scenerio_year)}></ScenerioSummary>
                                    }
                                </div> </>}
                            {scenerioTableData && scenerioTableData?.slice(Number(sceneriores.scenerio_year)).length > 0 && <>
                                <h6 className="text-center">Scnerio according to the {Number(sceneriores.scenerio_year) + 1} {(Number(sceneriores.scenerio_year) + 1 !== 30 ? 'to 30 years' : 'th year')} </h6>
                                <div style={{ overflowX: 'auto', width: '100%' }} className="mt-5"  >
                                    <div>
                                        <Nav fill variant="tabs" defaultActiveKey={showtable}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-0" onClick={(e) => { setshowtable("detailedassumption") }}> Detailed Assumption</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-1" onClick={(e) => { setshowtable("incomeoveryear") }}>Income Over Year</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-3" onClick={(e) => { setshowtable("expenses") }}>Expenses</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-4" onClick={(e) => { setshowtable("annalysis") }}>Annalysis</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-5" onClick={(e) => { setshowtable("summary") }}>Summary</Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                    </div>
                                    {showtable == "detailedassumption" &&
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>year</th>
                                                    <th>Estimate/Actual</th>
                                                    <th>Growth Assumption</th>
                                                    <th>Rental Growth</th>
                                                    <th>Inflation</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {scenerioTableData?.slice(Number(sceneriores.scenerio_year))?.map((item, index) => {

                                                    return (<>
                                                        <tr key={index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year) + 1)}>
                                                            <td>{item.currentYear}</td>
                                                            <td>

                                                                {item.estimate_heading == "0" ? "Estimate" : item?.estimate_heading == "1" ? "Actual" : ""}
                                                            </td>
                                                            <td>
                                                                {item.growth_assump + "%"}
                                                            </td>
                                                            <td>
                                                                {item.rental_growth + "%"}
                                                            </td>
                                                            <td>
                                                                {item.inflation + "%"}
                                                            </td>
                                                            <td>
                                                                <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                            </td>
                                                            {showactionbtns[index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))] ? <>
                                                                <td>
                                                                    <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                                </td>
                                                                <td>
                                                                    <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year)))}></i>
                                                                </td>
                                                            </> : ''}

                                                        </tr>


                                                    </>)
                                                })}


                                            </tbody>
                                        </Table>}
                                    {showtable == "incomeoveryear" && <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>year</th>
                                                <th>Weekly rental income</th>
                                                <th>Gross Annual Rental Income</th>
                                                <th>Weeks Vacant</th>
                                                <th>Vacancy Rate(weeks/52)</th>
                                                <th>Rental Loss</th>
                                                {/* <th>Actual Rental Income</th> */}
                                                <th>Letting Fee(weeks)</th>
                                                <th>Letting Fee($)</th>
                                                <th>Weeks Owner Occupied</th>
                                                <th>Owner Occupied Rent Saved</th>
                                                <th>Adjusted Income</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {scenerioTableData?.slice(Number(sceneriores.scenerio_year))?.map((items, index) => {
                                                return (<>
                                                    <tr key={index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year) + 1)}>
                                                        <td>{items?.currentYear}</td>
                                                        <td>
                                                        {contextValues.Currency} {(items?.weekly_rental_income)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.gross_annual_rental)?.toFixed(3)}</td>
                                                        <td>
                                                            {(items?.vacant_week)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.vacant_rate)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.rental_loss)?.toFixed(3)}</td>
                                                        {/* <td>{(items?.actual_rental_inc).toFixed(3)}</td> */}
                                                        <td>
                                                        {contextValues.Currency}  {(items?.letting_fee_weeks)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.letting_fee)?.toFixed(3)}</td>
                                                        <td>
                                                            {(items?.owner_occupied_week)?.toFixed(3)}
                                                        </td>
                                                        <td>{contextValues.Currency}{(items?.owner_rental_saved)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.adjusted_income)?.toFixed(3)}</td>
                                                        <td>
                                                            <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                        </td>
                                                        {showactionbtns[index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year) + 1)] ? <>
                                                            <td>
                                                                <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                            </td>
                                                            <td>
                                                                <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year)))}></i>
                                                            </td>
                                                        </> : ''}
                                                    </tr>


                                                </>)
                                            })}


                                        </tbody>
                                    </Table>}
                                    {showtable == "expenses" && <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>year</th>
                                                <th>Total Loan Amount </th>
                                                <th>Fixed Loan Amount </th>
                                                <th>Variable Loan Amount </th>
                                                <th>Loan Interest Fixed</th>
                                                <th>Loan Interest Variable</th>
                                                <th>Total Loan Interest Fixed</th>
                                                <th>Total Loan Interest Variable</th>
                                                <th>Total Loan Interest </th>
                                                <th>Property Manangment % </th>
                                                <th> Total Property Manangment</th>
                                                <th> building Landloard Insurance</th>
                                                <th>Water Rates</th>
                                                <th>Council Rates</th>
                                                <th>Maintainance</th>
                                                <th>Forcast annual cash expense</th>
                                                <th>Land Tax (if applicable)</th>
                                                <th>Principal Payments Fixed</th>
                                                <th>Principal Payments Variable</th>
                                                <th>Principal Payments</th>
                                                <th>Forcast Annual Non-deductable Expenses</th>

                                                <th>Potential annual net cash outflow</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {scenerioTableData?.slice(Number(sceneriores.scenerio_year))?.map((items, index) => {
                                                return (<>
                                                    <tr key={index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year) + 1)}>
                                                        <td>{items?.currentYear}</td>
                                                        <td>{contextValues.Currency}{(items?.total_loan_amount)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.loanamount_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.loanamount_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{items?.Loan_interest_fixed}</td>
                                                        <td>{contextValues.Currency}{items?.Loan_interest_variable}</td>
                                                        <td>{contextValues.Currency}{(items?.totalLoan_interest_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.totalLoan_interest_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_loan_interest)?.toFixed(3)}</td>
                                                        <td>{(items?.property_manag_perc)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_property_manage_fee)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.building_insuar)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.water_rates)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.Council_rates)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.maintaince_allownce)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.forcast_annual_cash_exp)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.land_tax)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_pay)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.forcast_annual_non_deductexpe)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.potential_annual_net_cash_outflow)?.toFixed(3)}</td>
                                                        <td>
                                                            <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                        </td>
                                                        {showactionbtns[index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year) + 1)] ? <>
                                                            <td>
                                                                <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                            </td>
                                                            <td>
                                                                <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year)))}></i>
                                                            </td>
                                                        </> : ''}
                                                    </tr>
                                                </>)
                                            })}
                                        </tbody>
                                    </Table>}
                                    {showtable == "annalysis" && <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>year</th>
                                                <th>Property Value</th>
                                                {/* <th>Loan Amount Fixed</th>
                                                <th>Loan Amount Variable</th>
                                                <th>Total Loan Amount</th> */}
                                                <th>Equity</th>
                                                {/* <th>Principal Payment Fixed</th>
                                                <th>Principal Payment Cummulative Fixed</th>
                                                <th>Principal Payment Variable</th>
                                                <th>Principal Payment Cummulative Variable</th>
                                                <th>Total Principal Payment</th>
                                                <th>Total Principal Payment Cummulative</th>
                                                <th>Interest Rate Fixed</th>
                                                <th>Interest Rate Variable</th> */}
                                                <th>Net Adjusted income</th>
                                                <th>Gross Yield %</th>
                                                <th>Cash Outflows</th>
                                                <th>Interest Expenses Fixed</th>
                                                <th>Interest Expenses Variable</th>
                                                <th>Total Interest Expenses</th>
                                                <th>Operating Expenses</th>
                                                <th>Net Annualcashflow</th>
                                                <th>Net Annualcashflow Cummulative</th>
                                                <th>Net Yield %</th>
                                                <th>Capital Annual Growth</th>
                                                <th>Capital Cummulative Growth</th>
                                                <th>Total Performance</th>
                                                <th>Total Performance Including Principal</th>
                                                <th>Cash On Cash Returns %</th>
                                                <th>Return On Invested Capital %</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {scenerioTableData?.slice(Number(sceneriores.scenerio_year))?.map((items, index) => {
                                                return (<>
                                                    <tr key={index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year) + 1)}>
                                                        <td>{items?.currentYear}</td>
                                                        <td>{contextValues.Currency}{(items?.property_value)?.toFixed(3)}</td>
                                                        {/* <td>{contextValues.Currency}{(items?.loanamount_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.loanamount_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_loan_amount).toFixed(3)}</td> */}
                                                        <td>{contextValues.Currency}{(items?.equity)?.toFixed(3)}</td>
                                                        {/* <td>{contextValues.Currency}{(items?.principal_payment_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_fixed_cummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.principal_payment_variable_cummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_principal_payment)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.totalprincipalpaymentcummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.Loan_interest_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.Loan_interest_variable)?.toFixed(3)}</td> */}
                                                        <td>{contextValues.Currency}{(items?.gross_income)?.toFixed(3)}</td>
                                                        <td>{(items?.grossyield)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.cashoutflow)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.interest_expense_fixed)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.interest_expense_variable)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_interest_expence)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.operating_expenses)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.net_annual_cash_flow)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.net_annual_cashflow_cummulative)?.toFixed(3)}</td>
                                                        <td>{(items?.netyeild)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.capital_growth_annual)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.capital_growth_annual_cummulative)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_performance)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.total_performance_principal)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.cash_on_cash_return)?.toFixed(3)}</td>
                                                        <td>{contextValues.Currency}{(items?.return_interest_capital)?.toFixed(3)}</td>
                                                        <td>
                                                            <i class="ri-pencil-fill fs-4 text-secondary" onClick={(e) => { handleEditClick(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                        </td>
                                                        {showactionbtns[index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year) + 1)] ? <>
                                                            <td>
                                                                <i class="ri-checkbox-circle-fill" onClick={(e) => { submitMainatianancedata(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year))) }}></i>
                                                            </td>
                                                            <td>
                                                                <i class="ri-refresh-line fs-4 text-secondary" onClick={() => handleResetChanges(index + (sceneriores.scenerio_year == 0 ? 0 : Number(sceneriores.scenerio_year)))}></i>
                                                            </td>
                                                        </> : ''}
                                                    </tr>
                                                </>)
                                            })}
                                        </tbody>
                                    </Table>}
                                    {showtable == 'summary' &&
                                        <ScenerioSummary scenerioTable={scenerioTableData?.slice(Number(sceneriores.scenerio_year))}></ScenerioSummary>
                                    }
                                </div>
                            </>}


                        </div>
                    )}

                </div>
            </div>

            <SweetAlert
                warning
                confirmBtnCssClass="alertpop"
                title={` Are you sure you want to discard this scenerio ? `}
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                show={show}
                onConfirm={deleteScenerio}
                onCancel={onreject}
                btnSize="md"
                showCancel
                cancelBtnBsStyle="danger"
            ></SweetAlert>

            <Modal show={contextValues.toggleChnageScenerio} onHide={ChangescenerioModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Actual Readings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='conatiner'>
                        <div className='row p-3'>
                            <label>Growth Assumption</label>
                            <input placeholder="Growth assumption" name="growth_assump"
                                value={showinputs.growth_assump}
                                onChange={(e) => handleChangeInput(e, selectedRowIndex)}
                                defaultValue={selectedObject?.growth_assump}
                                className="form-control mb-2"
                            />
                            <label>Rental Growth </label>
                            <input placeholder=" Rental growth" name="rental_growth"
                                value={showinputs.rental_growth}
                                onChange={(e) => handleChangeInput(e, selectedRowIndex)}
                                defaultValue={selectedObject?.rental_growth}
                                className="form-control mb-2"
                            />
                            <label>Inflation</label>
                            <input placeholder="Inflation" name="inflation"
                                value={showinputs.inflation}
                                onChange={(e) => handleChangeInput(e, selectedRowIndex)}
                                defaultValue={selectedObject?.inflation}
                                className="form-control mb-2"
                            />


                        </div>
                    </div></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ChangescenerioModal}>
                        Close
                    </Button>
                    <Button variant="primary"
                        onClick={() => { handleInputEditSubmit(selectedRowIndex) }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
        <Footer></Footer>
    </>)
}


export default ChangeScenerio