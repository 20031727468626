import * as React from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../component/Services/apiservices";
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import ScenerioSummary from "../../component/scenerio_summary_annalysis..js";

const ScenerioDetail = () => {
    const { id } = useParams()
    const [scenerioTableData, setScenerioTableData] = useState([]);
    const [showtable, setshowtable] = useState("summary")
    const [showloader, setshowloader] = useState(false)
    const navigate = useNavigate()
    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            getScenerioData()
        }
        didMountRef.current = false;
    }, [])

    const getScenerioData = () => {
        let dataString = {
            scenerio_id: id
        }
        ApiService.postData(`/property/getsceneriodetail`, dataString)
            .then((res) => {
                if (res.status === 'success') {
                    setScenerioTableData(res.scenerodetail.scenerio_table_data)
                } else if (res.status === 'error' && (res.message === 'session_expired' || res.message === 'Account Inactive')) {
                    localStorage.removeItem('USER_TOKEN');
                    navigate('/');
                }

            })
            .catch((error) => {
                console.error('Error fetching properties:', error);
            });
    };



    return (<>
        <Header></Header>
        <section className="section-gap-md">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div style={{ overflowX: 'auto', width: '100%' }} className="mt-5"  >
                            <div>
                                <Nav fill variant="tabs" defaultActiveKey={showtable}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="link-0" onClick={(e) => { setshowtable("detailedassumption") }}> Detailed Assumption</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="link-1" onClick={(e) => { setshowtable("incomeoveryear") }}>Income Over Year</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="link-3" onClick={(e) => { setshowtable("expenses") }}>Expenses</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="link-4" onClick={(e) => { setshowtable("annalysis") }}>Annalysis</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="link-5" onClick={(e) => { setshowtable("summary") }}>Summary</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                            {showtable == "detailedassumption" &&
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>year</th>
                                            <th>Estimate/Actual</th>
                                            <th>Growth Assumption</th>
                                            <th>Rental Growth</th>
                                            <th>Inflation</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {scenerioTableData?.map((item, index) => {
                                            return (<>
                                                <tr key={index}>
                                                    <td>{item.currentYear}</td>
                                                    <td>

                                                        {item.estimate_heading == "0" ? "Estimate" : item?.estimate_heading == "1" ? "Actual" : ""}
                                                    </td>
                                                    <td>
                                                        {item.growth_assump + "%"}
                                                    </td>
                                                    <td>
                                                        {item.rental_growth + "%"}
                                                    </td>
                                                    <td>
                                                        {item.inflation + "%"}
                                                    </td>


                                                </tr>


                                            </>)
                                        })}


                                    </tbody>
                                </Table>}
                            {showtable == "incomeoveryear" && <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>year</th>
                                        <th>Weekly rental income</th>
                                        <th>Gross Annual Rental Income</th>
                                        <th>Weeks Vacant</th>
                                        <th>Vacancy Rate(weeks/52)</th>
                                        <th>Rental Loss</th>
                                        {/* <th>Actual Rental Income</th> */}
                                        <th>Letting Fee(weeks)</th>
                                        <th>Letting Fee($)</th>
                                        <th>Weeks Owner Occupied</th>
                                        <th>Owner Occupied Rent Saved</th>
                                        <th>Adjusted Income</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {scenerioTableData?.map((items, index) => {
                                        return (<>
                                            <tr key={index}>
                                                <td>{items?.currentYear}</td>
                                                <td>
                                                    {(items?.weekly_rental_income)?.toFixed(3)}
                                                </td>
                                                <td>{(items?.gross_annual_rental)?.toFixed(3)}</td>
                                                <td>
                                                    {(items?.vacant_week)?.toFixed(3)}
                                                </td>
                                                <td>{(items?.vacant_rate)?.toFixed(3)}</td>
                                                <td>{(items?.rental_loss)?.toFixed(3)}</td>
                                                {/* <td>{(items?.actual_rental_inc).toFixed(3)}</td> */}
                                                <td>
                                                    {(items?.letting_fee_weeks)?.toFixed(3)}
                                                </td>
                                                <td>{(items?.letting_fee)?.toFixed(3)}</td>
                                                <td>
                                                    {(items?.owner_occupied_week)?.toFixed(3)}
                                                </td>
                                                <td>{(items?.owner_rental_saved)?.toFixed(3)}</td>
                                                <td>{(items?.adjusted_income)?.toFixed(3)}</td>

                                            </tr>


                                        </>)
                                    })}


                                </tbody>
                            </Table>}
                            {showtable == "expenses" && <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>year</th>
                                        <th>Total Loan Amount </th>
                                        <th>Fixed Loan Amount </th>
                                        <th>Variable Loan Amount </th>
                                        <th>Loan Interest Fixed</th>
                                        <th>Loan Interest Variable</th>
                                        <th>Total Loan Interest Fixed</th>
                                        <th>Total Loan Interest Variable</th>
                                        <th>Total Loan Interest </th>
                                        <th>Property Manangment % </th>
                                        <th> Total Property Manangment</th>
                                        <th> building Landloard Insurance</th>
                                        <th>Water Rates</th>
                                        <th>Council Rates</th>
                                        <th>Maintainance</th>
                                        <th>Forcast annual cash expense</th>
                                        <th>Land Tax (if applicable)</th>
                                        <th>Principal Payments Fixed</th>
                                        <th>Principal Payments Variable</th>
                                        <th>Principal Payments</th>
                                        <th>Forcast Annual Non-deductable Expenses</th>

                                        <th>Potential annual net cash outflow</th>


                                    </tr>
                                </thead>
                                <tbody>

                                    {scenerioTableData?.map((items, index) => {
                                        return (<>
                                            <tr key={index}>
                                                <td>{items?.currentYear}</td>
                                                <td>{(items?.total_loan_amount)?.toFixed(3)}</td>
                                                <td>{(items?.loanamount_fixed)?.toFixed(3)}</td>
                                                <td>{(items?.loanamount_variable)?.toFixed(3)}</td>
                                                <td>{items?.Loan_interest_fixed}</td>
                                                <td>{items?.Loan_interest_variable}</td>
                                                <td>{(items?.totalLoan_interest_fixed)?.toFixed(3)}</td>
                                                <td>{(items?.totalLoan_interest_variable)?.toFixed(3)}</td>
                                                <td>{(items?.total_loan_interest)?.toFixed(3)}</td>
                                                <td>{(items?.property_manag_perc)?.toFixed(3)}</td>
                                                <td>{(items?.total_property_manage_fee)?.toFixed(3)}</td>
                                                <td>{(items?.building_insuar)?.toFixed(3)}</td>
                                                <td>{(items?.water_rates)?.toFixed(3)}</td>
                                                <td>{(items?.Council_rates)?.toFixed(3)}</td>
                                                <td>{(items?.maintaince_allownce)?.toFixed(3)}</td>
                                                <td>{(items?.forcast_annual_cash_exp)?.toFixed(3)}</td>
                                                <td>{(items?.land_tax)?.toFixed(3)}</td>
                                                <td>{(items?.principal_payment_fixed)?.toFixed(3)}</td>
                                                <td>{(items?.principal_payment_variable)?.toFixed(3)}</td>
                                                <td>{(items?.principal_pay)?.toFixed(3)}</td>
                                                <td>{(items?.forcast_annual_non_deductexpe)?.toFixed(3)}</td>
                                                <td>{(items?.potential_annual_net_cash_outflow)?.toFixed(3)}</td>

                                            </tr>
                                        </>)
                                    })}
                                </tbody>
                            </Table>}
                            {showtable == "annalysis" && <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>year</th>
                                        <th>Property Value</th>
                                        <th>Loan Amount Fixed</th>
                                        <th>Loan Amount Variable</th>
                                        <th>Total Loan Amount</th>
                                        <th>Equity</th>
                                        <th>Principal Payment Fixed</th>
                                        <th>Principal Payment Cummulative Fixed</th>
                                        <th>Principal Payment Variable</th>
                                        <th>Principal Payment Cummulative Variable</th>
                                        <th>Total Principal Payment</th>
                                        <th>Total Principal Payment Cummulative</th>
                                        <th>Interest Rate Fixed</th>
                                        <th>Interest Rate Variable</th>
                                        <th>Net Adjusted income</th>
                                        <th>Gross Yield %</th>
                                        <th>Cash Outflows</th>
                                        <th>Interest Expenses Fixed</th>
                                        <th>Interest Expenses Variable</th>
                                        <th>Total Interest Expenses</th>
                                        <th>Operating Expenses</th>
                                        <th>Net Annualcashflow</th>
                                        <th>Net Annualcashflow Cummulative</th>
                                        <th>Net Yield %</th>
                                        <th>Capital Annual Growth</th>
                                        <th>Capital Cummulative Growth</th>
                                        <th>Total Performance</th>
                                        <th>Total Performance Including Principal</th>
                                        <th>Cash On Cash Returns %</th>
                                        <th>Return On Invested Capital %</th>


                                    </tr>
                                </thead>
                                <tbody>

                                    {scenerioTableData?.map((items, index) => {
                                        return (<>
                                            <tr key={index}>
                                                <td>{items?.currentYear}</td>
                                                <td>{(items?.property_value)?.toFixed(3)}</td>
                                                <td>{(items?.loanamount_fixed)?.toFixed(3)}</td>
                                                <td>{(items?.loanamount_variable)?.toFixed(3)}</td>
                                                <td>{(items?.total_loan_amount).toFixed(3)}</td>
                                                <td>{(items?.equity)?.toFixed(3)}</td>
                                                <td>{(items?.principal_payment_fixed)?.toFixed(3)}</td>
                                                <td>{(items?.principal_payment_fixed_cummulative)?.toFixed(3)}</td>
                                                <td>{(items?.principal_payment_variable)?.toFixed(3)}</td>
                                                <td>{(items?.principal_payment_variable_cummulative)?.toFixed(3)}</td>
                                                <td>{(items?.total_principal_payment)?.toFixed(3)}</td>
                                                <td>{(items?.totalprincipalpaymentcummulative)?.toFixed(3)}</td>
                                                <td>{(items?.Loan_interest_fixed)?.toFixed(3)}</td>
                                                <td>{(items?.Loan_interest_variable)?.toFixed(3)}</td>
                                                <td>{(items?.gross_income)?.toFixed(3)}</td>
                                                <td>{(items?.grossyield)?.toFixed(3)}</td>
                                                <td>{(items?.cashoutflow)?.toFixed(3)}</td>
                                                <td>{(items?.interest_expense_fixed)?.toFixed(3)}</td>
                                                <td>{(items?.interest_expense_variable)?.toFixed(3)}</td>
                                                <td>{(items?.total_interest_expence)?.toFixed(3)}</td>
                                                <td>{(items?.operating_expenses)?.toFixed(3)}</td>
                                                <td>{(items?.net_annual_cash_flow)?.toFixed(3)}</td>
                                                <td>{(items?.net_annual_cashflow_cummulative)?.toFixed(3)}</td>
                                                <td>{(items?.netyeild)?.toFixed(3)}</td>
                                                <td>{(items?.capital_growth_annual)?.toFixed(3)}</td>
                                                <td>{(items?.capital_growth_annual_cummulative)?.toFixed(3)}</td>
                                                <td>{(items?.total_performance)?.toFixed(3)}</td>
                                                <td>{(items?.total_performance_principal)?.toFixed(3)}</td>
                                                <td>{(items?.cash_on_cash_return)?.toFixed(3)}</td>
                                                <td>{(items?.return_interest_capital)?.toFixed(3)}</td>

                                            </tr>
                                        </>)
                                    })}
                                </tbody>
                            </Table>}
                            {showtable == 'summary' &&
                                <ScenerioSummary scenerioTable={scenerioTableData}></ScenerioSummary>
                            }
                        </div>
                    </div>
                </div>
            </div>


        </section>
        
        <Footer></Footer>
    </>)
}


export default ScenerioDetail