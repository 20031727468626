
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useNavigate } from "react-router-dom";
import Accountsidebar from "./account_sidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import { ApiService } from "../../component/Services/apiservices";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import DataContext from "../../component/Elements/context";
import ScenerioModal from "../../component/modals/scenerio_modal";
import SearchTagModal from "../../component/modals/search_tag_modal";
import AddAddressModal from "../../component/modals/add_property_address";
import PropertyAddress from "../../component/modals/property_address_modal";
import Loader from "../../component/utils/loader";

const MyProperty = () => {
  const contextValues = useContext(DataContext)
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const [myproperties, setmyproperties] = useState([])
  const [deleteId, setdeleteId] = useState({});
  const [showReset, setShowReset] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [IsLoading, setIsLoading] = useState(false);
  const [propertyId, setpropertyId] = useState('');
  const [property_img_url, setpropertyImgUrl] = useState(null);
  const [hasMore, setHasmore] = useState(false)
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      fetchProperties()
    }
    didMountRef.current = false;
  }, [])
  const getMyPropertyData = () => {
    let pageNumber = 1
    setIsLoading(true)
    ApiService.fetchData(`/getpropertylist?page=${pageNumber}`)
      .then((res) => {
        if (res.status === 'success') {
          const { propertyList, currentPage, totalPages } = res;
          setmyproperties((prevProperties) => [
            ...prevProperties,
            ...propertyList,
          ]);
          setIsLoading(false)

        } else if (res.status === 'error' && (res.message === 'session_expired' || res.message === 'Account Inactive')) {
          localStorage.removeItem('USER_TOKEN');
          navigate('/');
        }
        else {
          setIsLoading(false)
        }

      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching properties:', error);
      });
  };

  const deleteproperty = (itemid) => {
    const dataString = {
      purchase_id: deleteId._id,
      property_id: deleteId.property_data._id
    }
    ApiService.postData('/deleteproperty', dataString).then((res) => {
      if (res?.status == "success") {
        toast.success(res.message)
        setTimeout(() => {
          setShow(false)
          fetchProperties('delete')
          setHasmore(true)
        }, 500)
      } else if (res?.status == "error" && res.message == 'session_expired') {
        toast.error(res?.data?.message)
        localStorage.removeItem("USER_TOKEN")
        navigate('/')
      }
      else {
        setShow(false)
        toast.error(res?.message)
      }
    }).catch((error) => { setShow(false) })

  }
  const onDeleteProp = (item) => {
    setdeleteId(item)
    setShow(!show)
  }
  const scenerioModal = (id) => {
    setpropertyId(id)
    contextValues.setToggleScenerio(!contextValues.toggleScenerio)
  }
  const tagModal = (id) => {
    setpropertyId(id)
    contextValues.setToggleSearchTag(!contextValues.toggleSearchTag)
  }
  const handleClose = () => {
    contextValues.setAddress('')
    contextValues.setToggletrackModal(false)
  }


  const fetchProperties = (type = "") => {
    let pageNumber = type === "delete" ? 1 : Math.ceil(myproperties.length / 10) + 1;
    setIsLoading(true)
    if (searchQuery.length > 2) {
      setShowReset(true)
      ApiService.postData(`/searchPropertiesByTag?page=${pageNumber}`, { tagValue: searchQuery })
        .then(handleResponse)
        .catch(handleError);
    } else {
      ApiService.fetchData(`/getpropertylist?page=${pageNumber}`)
        .then(handleResponse)
        .catch(handleError);
    }
  };
  const handleResponse = (res) => {

    if (res.status === "success") {
      const { propertyList, currentPage, totalPages, property_img_url } = res;
      setmyproperties((prev) => (currentPage === 1 ? propertyList : [...prev, ...propertyList]));
      setHasmore(currentPage < totalPages);
      setpropertyImgUrl(property_img_url);
      setIsLoading(false)
    } else if (res.status === "error" && (res.message === "session_expired" || res.message === "Account Inactive")) {
      localStorage.removeItem("USER_TOKEN");
      navigate("/");
      setIsLoading(false)
    }
    else {
      setIsLoading(false)
    }
  };
  const handleError = (error) => {
    setIsLoading(false)
    console.error("Error fetching properties:", error);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    if (e.target.value.length > 2) {
      setmyproperties([]);
      fetchProperties("delete");
    }
    if (e.target.value.length == 0) {
      setmyproperties([]);
      setShowReset(false)
      fetchProperties("delete");
    }

  };

  const reSet = () => {
    setSearchQuery("");
    setShowReset(false)
    setmyproperties([])
    getMyPropertyData("delete");
  };

  const addAddressModal = () => {
    contextValues.setToggleAddAddress(!contextValues.toggleAddAddress)
  }


  return (<>
    <Header></Header>
    <section className="section-gap-md">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Accountsidebar />
          </div>
          <div className="col-lg-9">
            <div className="ccpanel">
              <div className="ccpanel-header d-flex justify-content-between">
                <h5 className="mb-0">My Properties</h5>
                <button
                  className="btn btn-primary btn-md"
                  type="button"
                  name="submit"
                  onClick={(e) => {
                    addAddressModal()
                  }}

                >
                  Add Property
                </button>
              </div>
              <div className="ccpanel-body">
                <div className="row g-3">
                  <div className={showReset ? 'col-10' : 'col-12'}>
                    <div className="form-group">
                      <input placeholder="Search by tag..." onChange={(e) => { handleSearchChange(e); }} value={searchQuery}
                      ></input>
                    </div>
                  </div>
                  {showReset && (
                    <dv className='col-2'>
                      <div className="d-flex">
                        <button className='btn btn-primary btn-full btn-lg' onClick={() => { reSet() }} >
                          Reset
                        </button>
                      </div>

                    </dv>
                  )}


                  <div className="table-responsive">
                    {IsLoading ?
                      <div className="text-center">
                        <Loader></Loader>
                      </div>
                      :
                      myproperties.length > 0 ?
                        <InfiniteScroll
                          dataLength={myproperties.length}
                          next={fetchProperties}
                          hasMore={hasMore}
                          loader={<h6>Loading...</h6>}
                          style={{ overflow: "hidden !important" }}
                        >
                          <table className="table table-striped table-bordered text-center">
                            <thead className="thead-dark">
                              <tr>
                                <th>Title</th>
                                <th>Equity</th>
                                <th>Net Annual Cashflow</th>
                                <th>Date Published</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {myproperties.length > 0 &&
                                myproperties.map((item, key) => {
                                  const date1 = new Date(item.property_data.created_at).toDateString();
                                  return (
                                    <tr key={key}>
                                      <td>
                                        <div className="d-flex align-items-center justify-content-start" >
                                          <img
                                            src={item?.property_data?.property_image ? property_img_url + item?.property_data?.property_image : '/img/logo.png'}
                                            alt="Property"
                                            className="img-thumbnail me-3"
                                            style={{ width: "80px", height: "auto" }}
                                          />
                                          <div>
                                            <a
                                              href={`/maintainance/${item?.property_data?._id}`}
                                              className="fw-bold text-decoration-none"
                                            >
                                              {item?.property_data?.property_name}
                                            </a>
                                            <div className="text-muted small">{item?.property_data?.property_adress}</div>

                                          </div>
                                        </div>
                                      </td>
                                      <td>{contextValues.Currency}{(item.maintenance_data.equity)?.toFixed(3)}</td>
                                      <td>{contextValues.Currency}{(item.maintenance_data.net_annual_cash_flow)?.toFixed(3)}</td>
                                      <td>{date1}</td>

                                      <td>
                                        <div className="d-flex justify-content-center gap-2">
                                          <a href={`/maintainance/${item?.property_data?._id}`} className="btn btn-sm btn-outline-primary">
                                            <i class="ri-pencil-fill"></i>
                                          </a>
                                          <a href="javascript:void(0)" className="btn btn-sm btn-outline-danger remove-file" onClick={() => { onDeleteProp(item) }}><i class="ri-delete-bin-fill"></i>
                                          </a>
                                          <a href="javascript:void(0)" className="btn btn-sm btn-outline-success remove-file" onClick={() => { navigate(`/createscenerio/${item?.property_data?._id}`) }}>WhatIf
                                          </a>
                                          <a href="javascript:void(0)" className="btn btn-sm btn-outline-warning remove-file" onClick={() => { scenerioModal(item?.property_data?._id) }} >Scenerios
                                          </a>
                                          <a href="javascript:void(0)" className="btn btn-sm btn-outline-secondary remove-file" onClick={() => { tagModal(item?.property_data?._id) }} >Tag
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </InfiniteScroll> : <p className="text-center">{' No Property Found'}</p>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SweetAlert
      warning
      confirmBtnCssClass="alertpop"
      title={` Are you sure you want to delete this property ? `}
      confirmBtnText="Confirm"
      cancelBtnText="Cancel"
      show={show}
      onConfirm={deleteproperty}
      onCancel={onDeleteProp}
      btnSize="md"
      showCancel
      cancelBtnBsStyle="danger"
    ></SweetAlert>
    {contextValues.toggleScenerio && <ScenerioModal propertyId={propertyId}></ScenerioModal>}
    {contextValues.toggleSearchTag && <SearchTagModal propertyId={propertyId}></SearchTagModal>}
    {contextValues.toggleAddAddress && <AddAddressModal />}
    {contextValues.toggletrackModal && <PropertyAddress handleClose={handleClose}></PropertyAddress>}

    <Footer></Footer>

  </>)
}

export default MyProperty