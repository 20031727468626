import React, { useState, useEffect, useRef } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useForm, } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let apiServices = new ApiService()
const PropertyPurchase = () => {
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();
    const { id } = useParams()
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    const [propertyData, setPropertyData] = useState("")
    const [Bank, setBankData] = useState([])
    const [settlementstatus, setsettlementstatus] = useState("")
    const [datechngesettle, setchangedatesettle] = useState("")
    const [InterestdefaultValues, setInterestdefaultvalue] = useState({})
    const [showloader, setshowloader] = useState(false)
    const [datechnge, setchangedate] = useState("")
    const [Errorobject, setErrorobject] = useState({ purchase_percentage_fixed: "", purchase_percentage_variable: "" })


    useEffect(() => {
        const dataString = {
            id: id
        }
        if (didMountRef.current) {
            apiServices.getpropertydataPostRequest(dataString).then(res => {
                if (res.data.status == "success") {

                    if (res?.data?.purschasedata !== null) {

                        if (res?.data?.purschasedata?.purchase_conditional_date !== null) {
                            setsettlementstatus("conditional");
                        } else if (res?.data?.purschasedata?.purchase_unconitional_date !== null) {
                            setsettlementstatus("unconditional");
                        }
                        setValue("purchase_bank_name", res.data?.purschasedata?.purchase_bank_name);
                        setValue("purchase_intrest_rate", res.data?.purschasedata?.purchase_intrest_rate);
                        setValue("purchase_is_other_agent", res.data?.purschasedata?.purchase_is_other_agent);
                        setValue("purchase_is_buyer_agent", res.data?.purschasedata?.purchase_is_buyer_agent);
                        setValue("purchase_is_rebate", res.data?.purschasedata?.purchase_is_rebate);
                        setValue("purchase_loan_split", res.data?.purschasedata?.purchase_loan_split);
                        setValue("purchase_interest_fixed", res?.data?.purschasedata?.purchase_interest_fixed);
                        setValue("purchase_percentage_fixed", res?.data?.purschasedata?.purchase_percentage_fixed);
                        setValue("purchase_percentage_variable", res?.data?.purschasedata?.purchase_percentage_variable);
                        setValue("purchase_interest_variable", res?.data?.purschasedata?.purchase_interest_variable);
                        setValue("purchase_rental_pw", res?.data?.purschasedata?.purchase_rental_pw);
                        setValue("purchase_contract_date", moment(res?.data?.purschasedata?.purchase_contract_date).format('YYYY-MM-DD'));
                        setValue("purchase_unconitional_date", res?.data?.purschasedata?.purchase_unconitional_date);
                        setValue("purchase_conditional_date", res?.data?.purschasedata?.purchase_conditional_date);
                        setValue("purchase_settelment_date", res?.data?.purschasedata?.purchase_settelment_date);
                        setValue("lmiamount", res?.data?.purschasedata?.purchase_lmi_amt);
                        setValue("stampdutyamount", res?.data?.purschasedata?.purchase_stamp_duty_amt);
                        setValue("otherbate", res?.data?.purschasedata?.purchase_other_bate);
                        setValue("buyersagentfee", res?.data?.purschasedata?.purchase_buyers_agent_fee);
                        setValue("otheragentfee", res?.data?.purschasedata?.purchase_other_agent_fee);
                        setValue("adjustedcouncilrates", res?.data?.purschasedata?.purchase_adjusted_council_rates);
                        setValue("adjustedwaterrates", res?.data?.purschasedata?.purchase_adjusted_water_rates);
                        setValue("loantype", res?.data?.purschasedata?.purchase_loan_type);
                        setValue("termfixed", res?.data?.purschasedata?.purchase_term_fixed);
                        setValue("termvariable", res?.data?.purschasedata?.purchase_term_variable);
                        setValue("noofunits", res?.data?.purschasedata?.purchase_noof_unit);
                        setValue("fhogamount", res.data.purschasedata?.purchase_fhog_amt);
                        setValue("firsthomeowner", res.data.purschasedata?.purchase_first_home_owner);
                        setValue("lmiapplicable", res.data.purschasedata?.purchase_lmi_app);
                        setValue("structuralinspectionfee", res.data.purschasedata?.purchase_struc_inspect_fee);
                        setValue("structuralinspectionfeepaid", res.data.purschasedata?.purchase_struc_inspect_fee_paidstatus);
                        setValue("buildinginsurancefee", res.data.purschasedata?.purchase_building_ins);
                        setValue("buildinginsurancefeepaid", res.data.purschasedata?.purchase_building_ins_paidstatus);
                        setValue("timberinspectionfee", res.data.purschasedata?.purchase_timber_fee);
                        setValue("timberinspectionfeepaid", res.data.purschasedata?.purchase_timber_fee_paidstatus);
                        setValue("plumbinginspectionfee", res.data.purschasedata?.purchase_plumbing_fee);
                        setValue("plumbinginspectionfeepaid", res.data.purschasedata?.purchase_plumbing_fee_paidstatus);
                        setValue("electricalinspectionfee", res.data.purschasedata?.purchase_electrical_fee);
                        setValue("electricalinspectionfeepaid", res.data.purschasedata?.purchase_electrical_fee_paidstatus);
                        setValue("settlementagentfee", res.data.purschasedata?.purchase_settelment_fee);
                        setValue("settlementagentfeepaid", res.data.purschasedata?.purchase_settelment_fee_paidstatus);
                        setValue("pexafee", res.data.purschasedata?.purchase_pexa_fee);
                        setValue("pexafeepaid", res.data.purschasedata?.purchase_pexa_fee_paidstatus);
                        setValue("bankmortgagesetupfee", res.data.purschasedata?.purchase_bank_mortgage_fee);
                        setValue("bankmortgagesetupfeepaid", res.data?.purschasedata?.purchase_bank_mortgage_fee_paidstatus);
                        setValue("landgateregistrat", res.data?.purschasedata?.purchase_bank_land_gate_fee);
                        setValue("landgateregistratpaid", res.data?.purschasedata?.purchase_bank_land_gate_fee_paidstatus);
                        setValue("maintainceallowance", res.data?.purschasedata?.maintainceallowance);
                        setValue("potential_deduction", res.data?.purschasedata?.purchase_potential_deduction);
                        setValue("anyotherfee", res.data?.purschasedata?.purchase_any_other_fee);
                    }

                    setPropertyData(res.data.data)

                }
            }).catch((errors) => { })
            apiServices.getdefaultvaluesrequest().then(res => {
                if (res?.data?.status == "success") {
                    //   setdefaultValues(res?.data?.defaultvalues)
                    if (res.data.status == "success") {
                        if (res?.data?.defaultvalues !== null) {
                            setInterestdefaultvalue(prevState => ({
                                ...prevState,
                                ['purchase_interest_fixed']: res?.data?.defaultvalues?.dfinterest_fixed,
                                ['purchase_interest_variable']: res?.data?.defaultvalues?.dfinterest_variable,
                            }));

                            setValue("otherbate", res?.data?.defaultvalues?.dfother_rebate);
                            setValue("buyersagentfee", res?.data?.defaultvalues?.dfbuyer_agent_fee);
                            setValue("otheragentfee", res?.data?.defaultvalues?.dfother_agent_fee);
                            setValue("loantype", res?.data?.defaultvalues?.dfloan_type);
                            setValue("termfixed", res?.data?.defaultvalues?.dfterm_fixed);
                            setValue("purchase_interest_fixed", res?.data?.defaultvalues?.dfinterest_fixed);
                            setValue("purchase_interest_variable", res?.data?.defaultvalues?.dfinterest_variable);
                            setValue("termvariable", res?.data?.defaultvalues?.dfterm_variable);
                            setValue("noofunits", res?.data?.defaultvalues?.dfnoof_unit);
                            setValue("fhogamount", res.data.defaultvalues?.dffhog_amount);
                            setValue("firsthomeowner", res.data.defaultvalues?.dffhog_home_owner);
                            setValue("structuralinspectionfee", res.data.defaultvalues?.dfstructural_insp_cost);
                            setValue("buildinginsurancefee", res.data.defaultvalues?.dfbuilding_ins_cost);
                            setValue("timberinspectionfee", res.data.defaultvalues?.dfpest_insp_cost);
                            setValue("plumbinginspectionfee", res.data.defaultvalues?.dfplumbing_inst_cost);
                            setValue("electricalinspectionfee", res.data.defaultvalues?.dfelectricalinspectionfee);
                            setValue("settlementagentfee", res.data.defaultvalues?.dfsettlement_agent_fee);
                            setValue("pexafee", res.data.defaultvalues?.dfpexa_fee);
                            setValue("bankmortgagesetupfee", res.data.defaultvalues?.dfbank_mortage_setup);
                            setValue("landgateregistrat", res.data?.defaultvalues?.dfland_regis);
                            setValue("maintainceallowance", res.data?.defaultvalues?.dfmaintaince_allownce);
                            setValue("anyotherfee", res.data?.defaultvalues?.dfany_other_fee);
                        }
                    }
                }

            }).catch((errors) => { })
            getBankData()

        }
        didMountRef.current = false;
    })

    const getBankData = () => {
        ApiService.fetchData('/property/getaustraliabank').then((res)=>{
            if(res.status === 'success'){
                setBankData(res.data)
            }
            else{
                setBankData([])
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    const fixedvariable = {
        fixed: watch("purchase_percentage_fixed"),
        variable: watch("purchase_percentage_variable"),
        contractdate: watch("purchase_contract_date"),
        settlementstatus: watch("settlementstatus"),
        lmiapplicable: watch("lmiapplicable"),
        interestcheckbox: watch("purchase_loan_split"),
        purchaserebate: watch('purchase_is_rebate'),
        buyersagent: watch('purchase_is_buyer_agent'),
        otherreagent: watch('purchase_is_other_agent'),
        anyotherfee: watch('anyotherfee')


    }

    useEffect(() => {
        
        if (fixedvariable.interestcheckbox == 'no') {
            chnageInterestvalues()
        }
    }, [fixedvariable.contractdate, datechnge, datechngesettle, fixedvariable.interestcheckbox])
  

    const chnageInterestvalues = () => {
        if (fixedvariable.interestcheckbox == 'no') {
            setValue("purchase_interest_fixed", InterestdefaultValues?.purchase_interest_fixed);
            setValue("purchase_percentage_fixed", 0);
            setValue("purchase_percentage_variable", 100);
            setValue("purchase_interest_variable", InterestdefaultValues?.purchase_interest_variable);
        }
    }
    // chnageInterestvalues()
    const onSubmit = (data) => {
        if (fixedvariable.variable !== '' && fixedvariable.fixed !== '') {

         
            if (Math.round(fixedvariable.fixed) + Math.round(fixedvariable.variable) < 100 || Math.round(fixedvariable.fixed) + Math.round(fixedvariable.variable) > 100) {
                setErrorobject({
                    ...Errorobject,
                    purchase_percentage_fixed: "Total percentage of variable and fixed should be of 100",
                    purchase_percentage_variable: "Total percentage of variable and fixed should be of 100"
                });
                return false
            }
            else {
            
                const totalcash = (Number(parseInt(data.adjustedcouncilrates) +
                    parseInt(data.adjustedwaterrates) +
                    parseInt(propertyData?.property_purchase_deposit) +
                    parseInt(data.stampdutyamount) +
                    parseInt(data.anyotherfee) 
                ) 
                )

                setshowloader(true)
                const dataString = {
                    purchase_bank_name: data.purchase_bank_name,
                    purchase_intrest_rate: fixedvariable.interestcheckbox == 'yes' ? 0 : data.purchase_intrest_rate,
                    purchase_loan_split: data.purchase_loan_split,
                    purchase_is_other_agent: 'yes',
                    purchase_is_buyer_agent: 'yes',
                    purchase_is_rebate: 'yes',
                    purchase_interest_fixed: data.purchase_interest_fixed,
                    purchase_percentage_fixed: data.purchase_percentage_fixed,
                    purchase_percentage_variable: data.purchase_percentage_variable,
                    purchase_interest_variable: data.purchase_interest_variable,
                    purchase_rental_pw: data.purchase_rental_pw,
                    purchase_contract_date: new Date(),
                    purchase_unconitional_date: new Date(),
                    purchase_conditional_date: new Date(),
                    purchase_settelment_date: new Date(),
                    purchase_lmi_amt: 0,
                    purchase_stamp_duty_amt: data.stampdutyamount,
                    purchase_other_bate: 0,
                    purchase_buyers_agent_fee: 0,
                    purchase_other_agent_fee: 0,
                    purchase_adjusted_council_rates: data.adjustedcouncilrates,
                    purchase_adjusted_water_rates: data.adjustedwaterrates,
                    purchase_total_cash_req: totalcash,
                    purchase_uncondit_thrdamount: 0,
                    purchase_condit_thrdamount: 0,
                    purchase_condit_secamount: 0,
                    purchase_potential_deduction: data.potential_deduction,
                    purchase_any_other_fee: data.anyotherfee,
                    // purchase_bank_value: data.bankvaluation,
                    // purchase_loan_amount: data.loanamount,
                    // purchase_deposit: data.deposit,
                    purchase_loan_type: data.loantype,
                    purchase_term_fixed: data.termfixed,
                    purchase_term_variable: data.termvariable,
                    purchase_noof_unit: data.noofunits,
                    purchase_fhog_amt: 0,
                    purchase_first_home_owner: 'yes',
                    purchase_lmi_app: 'yes',
                    purchase_struc_inspect_fee: 0,
                    purchase_struc_inspect_fee_paidstatus: 'unpaid',
                    purchase_building_ins: 0,
                    purchase_building_ins_paidstatus: 'unpaid',
                    purchase_timber_fee: 0,
                    purchase_timber_fee_paidstatus: 'unpaid',
                    purchase_plumbing_fee: 0,
                    purchase_plumbing_fee_paidstatus: 'unpaid',
                    purchase_electrical_fee: 0,
                    purchase_electrical_fee_paidstatus: 'unpaid',
                    purchase_settelment_fee: 0,
                    purchase_settelment_fee_paidstatus: 'unpaid',
                    purchase_pexa_fee: 0,
                    purchase_pexa_fee_paidstatus: 'unpaid',
                    purchase_bank_mortgage_fee: 0,
                    purchase_bank_mortgage_fee_paidstatus: 'unpaid',
                    purchase_bank_land_gate_fee: 0,
                    purchase_bank_land_gate_fee_paidstatus: 'unpaid',
                    maintainceallowance: data.maintainceallowance,
                    purchase_property_id: id,
                }

                apiServices.savepurchasedetailPostRequest(dataString).then(res => {
                    if (res.data.status == "success") {
                        setshowloader(false)
                        toast.success(res.data.message)
                        Navigate('/purchase-summary/' + id)
                    }
                    else if (res?.data?.status == "error" && res.data.message == 'session_expired') {
                        toast.error(res?.data?.message)
                        localStorage.removeItem("USER_TOKEN")
                        setshowloader(false)
                        Navigate('/')
                    }
                    else if (res?.data?.status === "error") {
                        toast.error(res?.data?.message);
                        setshowloader(false);
                    }
                   
                }).catch((error) => {
                    setTimeout(() => {
                        setshowloader(false)
                    }, 500)
                })
            }
        }
    };

    const rentalpwfunction = () => {
        setValue("purchase_percentage_variable", Math.round(100 - getValues("purchase_percentage_fixed")))
    }
    return (<>
        <Header />
        <section className="section-gap-md">
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3">
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Bank</label>
                                <select name="purchase_bank_name"
                                {...register("purchase_bank_name", {
                                })}
                                class="custom-select"
                            >
                                <option >Select Bank</option>
                                {Bank.map((item, index) => {
                                    return <option key={index} value={item.property_bank_name}>{item.property_bank_name}</option>
                                })}
                            </select>
                               
                                {/* {errors.purchase_bank_name && errors.purchase_bank_name.type === "required" && (
                                <small className="text-danger">Bank Name is required.</small>
                            )} */}
                            </div>
                        </div>
                        {fixedvariable.interestcheckbox == 'no' &&
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Current interest rate</label>
                                    <input
                                        type="number"
                                        name="purchase_intrest_rate"
                                        step="any"
                                        {...register("purchase_intrest_rate", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_intrest_rate && errors.purchase_intrest_rate.type === "required" && (
                                        <small className="text-danger">Current Interest Rate is required.</small>
                                    )}
                                </div>
                            </div>}


                        <div className="col-lg-4">
                            <label>Is Loan amount split into fixed and variable ?</label>
                            <select name="purchase_loan_split"
                                {...register("purchase_loan_split", {
                                })}
                                class="custom-select"
                            >
                                <option value="yes">Yes</option>
                                <option value="no" selected>No</option>
                            </select>
                        </div>

                        {fixedvariable.interestcheckbox == 'yes' && <>
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Interest Fixed</label>
                                    <input
                                        type="number"

                                        name="purchase_interest_fixed"
                                        step="any"
                                        {...register("purchase_interest_fixed", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_interest_fixed && errors.purchase_interest_fixed.type === "required" && (
                                        <small className="text-danger">Interest Fixed is required.</small>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Interest Variable</label>
                                    <input
                                        type="number"

                                        name="purchase_interest_variable"
                                        step="any"
                                        {...register("purchase_interest_variable", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_interest_variable && errors.purchase_interest_variable.type === "required" && (
                                        <small className="text-danger">Interest Variable is required.</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Percentage Fixed</label>
                                    <input
                                        type="number"

                                        name="purchase_percentage_fixed"
                                        step="any"
                                        defaultValue={0}
                                        onKeyUp={rentalpwfunction}
                                        {...register("purchase_percentage_fixed", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_percentage_fixed && errors.purchase_percentage_fixed.type === "required" && (
                                        <small className="text-danger">Percentage Fixed is required.</small>
                                    )}
                                    {Errorobject.purchase_percentage_fixed && <p className="text-danger">{Errorobject.purchase_percentage_fixed}</p>}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div class="form-group">
                                    <label>Percentage Variable</label>
                                    <input
                                        type="number"

                                        name="purchase_percentage_variable"
                                        step="any"
                                        defaultValue={100}
                                        {...register("purchase_percentage_variable", {
                                            required: true,
                                        })}
                                    />
                                    {errors.purchase_percentage_variable && errors.purchase_percentage_variable.type === "required" && (
                                        <small className="text-danger">Percentage Variable is required.</small>
                                    )}

                                    {Errorobject.purchase_percentage_variable && <p className="text-danger">{Errorobject.purchase_percentage_variable}</p>}
                                </div>
                            </div>

                        </>}



                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Rental pw</label>
                                <input
                                    type="number"
                                    name="purchase_rental_pw"
                                    step="any"
                                    {...register("purchase_rental_pw", {
                                        required: true,
                                    })}
                                />
                                {errors.purchase_rental_pw && errors.purchase_rental_pw.type === "required" && (
                                    <small className="text-danger">Rental pw is required.</small>
                                )}
                            </div>
                        </div>
                      
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Water Rates</label>
                                <input
                                    type="number"
                                    name="adjustedwaterrates"
                                    step="any"
                                    {...register("adjustedwaterrates", {
                                        required: true,

                                    })}

                                />
                                {errors.adjustedwaterrates && errors.adjustedwaterrates.type === "required" && (
                                    <small className="text-danger">Adjusted Water Rates is required.</small>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Council Rates</label>
                                <input
                                    type="number"
                                    name="adjustedcouncilrates"
                                    step="any"
                                    {...register("adjustedcouncilrates", {
                                        required: true,

                                    })}
                                />
                                {errors.adjustedcouncilrates && errors.adjustedcouncilrates.type === "required" && (
                                    <small className="text-danger">Adjusted Council Rates is required.</small>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="form-group">
                                <label>Stamp Duty Amount</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="stampdutyamount"
                                    {...register("stampdutyamount", {
                                        required: true,
                                    })}
                                // onKeyUp={rentalpwfunction}
                                />
                                {errors.stampdutyamount && errors.stampdutyamount.type === "required" && (
                                    <small className="text-danger">Stamp Duty Amount is required.</small>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Select Loan Type</label>

                                        <select {...register("loantype", {
                                            required: true,
                                        })} class="custom-select" name="loantype"
                                        // defaultValue={defaultValues?.dfloan_type}
                                        >
                                            <option value="P&I">P&I</option>
                                            <option value="Interest">Interest</option>
                                        </select>
                                        {errors.loantype && errors.loantype.type === "required" && (
                                            <small className="text-danger">Loan Type is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Term Fixed</label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfterm_fixed}
                                            name="termfixed"
                                            {...register("termfixed", {
                                                required: true,

                                            })}
                                        />
                                        {errors.termfixed && errors.termfixed.type === "required" && (
                                            <small className="text-danger">Term Fixed is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Term Variable</label>
                                        <input
                                            type="number"
                                            // defaultValue={defaultValues?.dfterm_variable}
                                            name="termvariable"
                                            step="any"
                                            {...register("termvariable", {
                                                required: true,

                                            })}
                                        />
                                        {errors.termvariable && errors.termvariable.type === "required" && (
                                            <small className="text-danger">Term Variable is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Number of Units</label>
                                        <input
                                            type="number"
                                            // defaultValue={defaultValues?.dfnoof_unit}
                                            name="noofunits"
                                            step="any"
                                            {...register("noofunits", {
                                                required: true,

                                            })}
                                        />
                                        {errors.noofunits && errors.noofunits.type === "required" && (
                                            <small className="text-danger">Noof Units is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="form-group">
                                        <label>Potential Deduction</label>
                                        <input
                                            type="number"
                                            step="any"
                                            defaultValue={0}
                                            name="potential_deduction"
                                            {...register("potential_deduction", {
                                                // required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.potential_deduction && errors.potential_deduction.type === "required" && (
                                            <small className="text-danger"> Potential Deduction is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Maintaince Allowance </label>
                                        <input
                                            type="number"
                                            step="any"
                                            // defaultValue={defaultValues?.dfmaintaince_allownce}
                                            name="maintainceallowance"
                                            {...register("maintainceallowance", {
                                                required: true,

                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.maintainceallowance && errors.maintainceallowance.type === "required" && (
                                            <small className="text-danger"> Maintainance allowance is required.</small>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Any Other Fee</label>
                                        <input
                                            type="number"
                                            step="any"
                                            name="anyotherfee"
                                            {...register("anyotherfee")}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>Building insurance cost</label>
                                        <input
                                            type="number"
                                            name="buildinginsurancefee"
                                            step="any"
                                            // defaultValue={defaultValues?.dfbuilding_ins_cost}
                                            {...register("buildinginsurancefee", {
                                                required: true,
                                            })}
                                        // onKeyUp={rentalpwfunction}
                                        />
                                        {errors.buildinginsurancefee && errors.buildinginsurancefee.type === "required" && (
                                            <small className="text-danger">Building insurance cost is required.</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <select class="custom-select" name="buildinginsurancefeepaid"

                                            {...register("buildinginsurancefeepaid", {
                                                required: true,
                                            })}>
                                            <option value='paid'>Paid</option>
                                            <option selected value='unpaid'>Unpaid</option>
                                        </select>

                                    </div>
                                </div>


                        <button type="submit" class="btn  btn-lg btn-primary" disabled={showloader}
                        >
                            {showloader ? (
                                <img src="/img/loder01.gif" width="60px" height="11px" />
                            ) : (
                                "Submit"
                            )}</button>
                    </div>
                </form>

            </div>
        </section>
        <Footer />
    </>)
}

export default PropertyPurchase

















