import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ApiService } from '../Services/apiservices';
import DataContext from '../Elements/context';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
function SearchTagModal({ propertyId }) {

    const navigate = useNavigate()
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const [showloader, setshowloader] = useState(false);
    const [tagName, setTagName] = useState();
    const [errormsg, seterrormsg] = useState();
    const [errorvaluemsg, seterrorvaluemsg] = useState();
    const [tagNameOther, setTagNameOther] = useState();
    const [tagValue, setTagValue] = useState();



    useEffect(() => {
        if (didMountRef.current) {
            getPropertydata()
        }
        didMountRef.current = false;
    }, []);

    const tagModal = () => {
        contextValues.setToggleSearchTag(!contextValues.toggleSearchTag)
    }

    const savePropertyTag=()=>{
        setshowloader(true)
        if(!tagName){
            seterrormsg('please select the tag name ')
            setshowloader(false)
            return
        }
        if(tagName=='Other' && !tagNameOther ){
            seterrormsg('please enter the name of other tag ')
            setshowloader(false)
            return
        }
        if(!tagValue ){
            seterrorvaluemsg('please enter the value of tag name ')
            setshowloader(false)
            return
        }
        const dataString={
            property_id:propertyId,
            property_tag_name:tagName=='Other'?tagNameOther:tagName,
            property_tag_name_value:tagValue
        }
        ApiService.postData('/createPropertyTag', dataString).then((res)=>{
            setshowloader(false)
            if(res.status=='success'){
                toast.success(res.message)
                tagModal()
            } else if (res.status === 'error' && (res.message === 'session_expired' || res.message === 'Account Inactive')) {
                toast.success(res.message)
                localStorage.removeItem('USER_TOKEN');
                navigate('/');
            }
            else{
                toast.success(res.message)
                tagModal()   
            }
            
        })
    }
    const getPropertydata=()=>{
        const dataString={
            property_id:propertyId
        }
        ApiService.postData('/property/getpropertyaddressdata',dataString).then((res)=>{
        if(res.status=='success'){
            if(res.property.property_tag_name=='Name' ||res.property.property_tag_name=='Location'){
                setTagName(res.property.property_tag_name)
            }
            else if(res.property.property_tag_name){
                setTagName('Other')
                setTagNameOther(res.property.property_tag_name)
            }
        
        setTagValue(res.property.property_tag_name_value)
        }
        })
    }

    return (<>
        <Modal show={contextValues.toggleSearchTag} onHide={(e) => tagModal()} backdrop="static"  >
            <div className="loginContant" style={{ padding: '20px' }}>
                <button type="button" class="modal-close" onClick={() => { tagModal() }}><i class="ri-close-line"></i></button>
                <div className='container'>
                    <div className="row g-3 p-3">
                        <div className='form-group'>
                            <select name="tag_name"
                                class="custom-select"
                                value={tagName}
                                onChange={(e) => { setTagName(e.target.value) }}
                            >
                                <option value="">Select Tag Name</option>
                                <option value="Name">Name</option>
                                <option value="Location">location</option>
                                <option value="Other">Other</option>
                            </select>
                           {errormsg && !tagName && <span className='text-danger'>{errormsg}</span>} 
                        </div>
                        {tagName=='Other' && <div className='form-group'>
                            <input name="tag_name"
                                value={tagNameOther}
                                onChange={(e) => { setTagNameOther(e.target.value) }}
                                placeholder='Enter other tag name'
                            >
                            </input>
                            {errormsg && tagName && !tagNameOther&& <span className='text-danger'>{errormsg}</span>}
                        </div>}
                      
                        <div className='form-group'>
                            <input name="tag_value"
                                value={tagValue}
                                onChange={(e) => { setTagValue(e.target.value) }}
                                placeholder='Enter tag value'
                            >
                            </input>
                            {errorvaluemsg && !tagValue && <span className='text-danger'>{errorvaluemsg}</span>}
                        </div>
                        <div className='formgroup mb-20'>
                            <button className='btn btn-primary btn-full btn-lg' disabled={showloader} onClick={()=>{savePropertyTag()}}>{showloader ? (
                                <img src="/img/loder01.gif" width="60px" height="11px" />
                            ) : (
                                "Save"
                            )}</button>
                        </div>


                    </div>
                </div>

            </div>

        </Modal>
    </>)
}

export default SearchTagModal