import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input'
import React, { useState, useEffect } from "react";
import { ApiService } from "../../component/Services/apiservices";
import { useForm, } from "react-hook-form"
import { Alert } from 'react-bootstrap';


function Loginmodal({ handleClose, setshowlogin, showlogin, id, type }) {
    let apiServices = new ApiService()

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm();
    const [step, setStep] = useState(1)
    const [otp, setOtp] = useState('');
    const [showloader, setshowloader] = useState('');
    const [userid, setuserid] = useState("")
    const userEmail = watch("verify_user_email")
    const useraddress = watch("user_regisaddress")
    const username = watch("user_registname")
    const userdob = watch("user_regisdob")
    const usermobilenumber = watch("user_registmobileno")
    const [errormsg, setErrorMessage] = useState("");
    const [successmsg, setSuccessMessage] = useState("");
    const [resendTimer, setResendTimer] = useState(30);
  
    
    const onSubmitregister = (data) => {
        if (data.user_registepassword !== data.user_resgisCpassword) {
            setErrorMessage("Confirm Password not match with Password")
            return
        }
        const dataString = {
            user_name: username,
            user_mobileno: usermobilenumber,
            user_dob: userdob,
            user_address: useraddress,
            user_password: data?.user_registepassword,
            user_id: userid,
            property_id: id !== null && id !== '' ? id : ''

        }
        apiServices.getuserregister(dataString).then((res) => {
            if (res.data.status == "success") {
                let token = res?.data?.token
                localStorage.setItem("USER_TOKEN", token)
                setSuccessMessage(res?.data?.message)
                setshowloader(false)
                if (id) {
                    window.location.reload()
                } else {
                    window.location.href = '/dashboard'
                }
                // setTimeout(() => {
                //     setshowlogin(false);
                // }, [2000])
            } else if (res?.data?.status == "error") {
                const errormsg = res?.data?.message;
                setErrorMessage(errormsg)
                setshowloader(false)
                // setTimeout(() => {
                //     setshowlogin(false);
                // }, [3000])
            }
        })
            .catch((error) => {
                setshowloader(false)
            })

    }


    useEffect(() => {
        if (type == 'signin') {
            setStep(1)
        }
        if (type == 'join') {
            setStep(2)
        }
    }, [type])
    useEffect(() => {
        const timerId = setInterval(() => {
            if (resendTimer > 0) {
                setResendTimer(resendTimer - 1);
            }
        }, 1000);
        return () => {
            clearInterval(timerId);
        };

    }, [resendTimer]);

    const continueregister = () => {
        setStep(5)
    }
    const gotologin = () => {
        setStep(1)
        setValue("user_email", " ")
        setValue("user_password", " ")
    }
    const gotoregister = (type) => {
        if (type == 'forget') {
            setErrorMessage('')
            setStep(6)
            setValue('forget_user_email', '')
            setOtp('')

        }
        else {
            setErrorMessage('')
            setStep(2)
            setValue("user_email", "")
            setValue('verify_user_email', ' ')
            setValue("user_registepassword", "")
            setValue("user_registname", "")
            setValue("user_registmobileno", "")
            setValue("user_regisaddress", "")
            setValue("user_regisdob", "")
            setOtp('')

        }
    }
    const onSubmitlogin = (data) => {
        setshowloader(true)
        const dataString = {
            user_email: data?.user_email,
            user_password: data?.user_password,
            property_id: id !== null && id !== '' ? id : ''
        }
        apiServices.getuserlogin(dataString).then((res) => {
            if (res.data.status == "success") {
                let token = res?.data?.token
                localStorage.setItem("USER_TOKEN", token);
                setTimeout(() => {
                    setshowloader(false)
                    if (id) {
                        window.location.reload()
                    } else {
                        window.location.href = '/dashboard'
                    }
                }, [1000])
            } else if (res?.data?.status == "error") {
                const errormsg = res?.data?.message;
                setErrorMessage(errormsg)
                setshowloader(false)
                setTimeout(() => {
                    setErrorMessage('')
                }, 2000)

            }
        })

    }
    const onSubmitverifyotp = (data) => {

        if (otp == "") {
            setErrorMessage("Otp is required")
            return
        }
        setshowloader(true)
        const dataString = {
            user_otp: otp,
            user_id: userid
        }

        apiServices.gettoverifyotp(dataString).then((res) => {
            if (res.data.status == "success") {
                setuserid(res?.data?.id)
                setSuccessMessage(res?.data?.message)
                setTimeout(() => {
                    setshowloader(false)
                    setErrorMessage('')
                    setSuccessMessage('')
                    setStep(4)

                }, 1000)

            } else if (res?.data?.status == "error") {
                const errormsg = res?.data?.message;
                setErrorMessage(errormsg)
                setshowloader(false)
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 1000)
            }
        })

    }
    const onSubmitsendotp = (data) => {

        const checkemailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data?.verify_user_email)
        if (!checkemailpattern) {
            setErrorMessage("Email is not correct")
            return
        }
        else {
            const email = data?.verify_user_email.toLowerCase();
            const dataString = {
                user_email: email,
            }
            setshowloader(true)
            apiServices.gettosendotp(dataString).then((res) => {
                if (res.data.status == "success") {
                    setuserid(res?.data?.id)
                    setSuccessMessage(res?.data?.message)
                    setshowloader(false)

                    setTimeout(() => {
                        setErrorMessage('')
                        setSuccessMessage('')
                        setStep(3)
                        setResendTimer(30);
                    }, 1000)
                } else if (res?.data?.status == "error") {
                    const errormsg = res?.data?.message;
                    setErrorMessage(errormsg)
                    setshowloader(false)
                    setTimeout(() => {
                        setErrorMessage('')
                        setSuccessMessage('')
                    }, 1000)
                }
            })

        }
    }

    const resendOTP = () => {
        // setshowloader(true)
        setErrorMessage('')
        setSuccessMessage('')
        setResendTimer(30);

        const dataString = {
            user_id: userid
        }

        apiServices.getresendotp(dataString).then((res) => {
            if (res.data.status == "success") {
                setSuccessMessage(res?.data?.message)
                setuserid(res?.data?.id)
                // setshowloader(false)
            } else if (res?.data?.status == "error") {

                const errormsg = res?.data?.message;
                setErrorMessage(errormsg)
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 1000)
                // setshowloader(false)
            }
        })

    };

    const onforgetPassord = (data) => {
        setshowloader(true)
        const dataString = {
            user_email: data?.forget_user_email,
        }
        ApiService.postData('/user/forgetpassword', dataString).then((res) => {
            if (res.status == "success") {
                setTimeout(() => {
                    setuserid(res.user_id)
                    setshowloader(false)
                    setStep(7)
                }, [1000])
            } else if (res?.status == "error") {

                const errormsg = res?.message;
                setErrorMessage(errormsg)
                setshowloader(false)
                setTimeout(() => {
                    setErrorMessage('')
                }, 2000)

            }
        })
    }

    const onSubmitforgetOtp = (data) => {
        if (otp == "") {
            setErrorMessage("Otp is required")
            return
        }
        setshowloader(true)
        const dataString = {
            user_otp: otp,
            user_id: userid
        }
        ApiService.postData('/user/verifyforgetotp', dataString).then((res) => {
            if (res.status == "success") {
                setuserid(res?.id)
                setSuccessMessage(res?.message)
                setTimeout(() => {
                    setshowloader(false)
                    setErrorMessage('')
                    setSuccessMessage('')
                    setStep(8)

                }, 1000)

            } else if (res?.status == "error") {
                const errormsg = res?.message;
                setErrorMessage(errormsg)
                setshowloader(false)
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 1000)
            }
        })
    }

    const resendforgetOTP = () => {
        setErrorMessage('')
        setSuccessMessage('')
        setResendTimer(30);
        const dataString = {
            user_id: userid
        }
        ApiService.postData('/user/resendforgetotp', dataString).then((res) => {
            if (res.status == "success") {
                setSuccessMessage(res?.message)
                setuserid(res?.id)
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 1000)

            } else if (res?.status == "error") {
                const errormsg = res?.message;
                setErrorMessage(errormsg)
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 1000)
            }
        })
    }

    const onChangePassword = (data) => {
        setshowloader(true)
        if(data.user_changepassword !==data.user_changeCpassword){
            setErrorMessage('Password not match with confirm password')
            setshowloader(false)
            return
        }
        const dataString = {
            user_id: userid,
            password: data.user_changepassword,
            confirmpass: data.user_changeCpassword
        }
        ApiService.postData('/user/changepassword', dataString).then((res) => {
            if (res.status == 'success') {
                setshowloader(false)
                setSuccessMessage(res?.message)
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                    setStep(1)
                }, 1000)

            }
            else if (res?.status == "error") {
                const errormsg = res?.message;
                setErrorMessage(errormsg)
                setshowloader(false)
                setTimeout(() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                }, 1000)
            }
        })
    }

    const onClickforget=()=>{
        setErrorMessage('')
        setValue('forget_user_email' , '')
        setValue('user_email' , '')
        setValue('user_password' , '')
        setValue('user_changeCpassword' , '')
        setValue('user_changepassword' , '')
        setOtp('')
        setStep(6)
    }
    return (
        <>
            <Modal show={showlogin} onHide={handleClose} className='loginModal'>
                <div className='loginContant'>
                    <img src="/img/logo.png" width="175" height="31" className='mb-20' />
                    <button type="button" class="modal-close" onClick={() => { handleClose() }}><i class="ri-close-line"></i></button>
                    {step == 1 && <>
                        <h4>Sign In</h4>
                        <p>Welcome back! Please signin to continue.</p>
                        {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                            {errormsg}
                        </Alert> : ''}
                        {successmsg ? <Alert key={'success'} className='success' variant='success'>
                            {successmsg}
                        </Alert> : ''}
                        <form onSubmit={handleSubmit(onSubmitlogin)}>
                            <div className='formgroup mb-20'>
                                <label><div>Email<span className='tx-red '>*</span></div></label>
                                <input type='email'  {...register("user_email", { required: true, })} name="user_email" class="form-control"></input>
                                {errors.user_email && errors.user_email.type === "required" && (
                                    <small className="text-danger">Email is required.</small>
                                )}
                            </div>
                            <div className='formgroup mb-20'>
                                <label><div>Password<span className='tx-red'>*</span></div><a href='javascript:void(0)'><span onClick={() => { onClickforget() }}>Forgot Password?</span></a></label>
                                <input type='password' name="user_password"  {...register("user_password", { required: true, })} ></input>
                                {errors.user_password && errors.user_password.type === "required" && (
                                    <small className="text-danger">Password is required.</small>
                                )}
                            </div>

                            <div className='formgroup mb-20'>
                                <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                ) : (
                                    "Sign In"
                                )}</button>
                            </div>
                        </form>

                        <div className='formgroup mb-20' onClick={() => { gotoregister() }}>
                            <p className='text-center'>New on Proptrail? Register Now.</p>
                        </div>
                    </>}

                    {/*Login End */}
                    {step == 2 && <>
                        <h4>Create an Account</h4>
                        <p>We will send you an Email to verify your Email Address</p>
                        <form onSubmit={handleSubmit(onSubmitsendotp)}>
                            {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                                {errormsg}
                            </Alert> : ''}
                            {successmsg ? <Alert key={'success'} className='success' variant='success'>
                                {successmsg}
                            </Alert> : ''}
                            <div className='formgroup mb-20'>
                                <label><div>Email<span className='tx-red '>*</span></div></label>
                                <input type='email'  {...register("verify_user_email", { required: true, })} name="verify_user_email" class="form-control"></input>
                                {errors.verify_user_email && errors.verify_user_email.type === "required" && (
                                    <small className="text-danger">Email is required.</small>
                                )}
                            </div>
                            <div className='formgroup mb-20'>
                                <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                ) : (
                                    "Verify"
                                )}</button>
                            </div>
                        </form>
                        <div className='formgroup mb-20' onClick={() => { gotologin() }}>
                            <p className='text-center'>Already have an account? Login.</p>
                        </div>
                    </>}
                    {/* Send Otp End */}
                    {
                        step == 3 && <>
                            <h4>Verify Otp</h4>
                            <p>We have sent a verification code to {userEmail} <a href='javascript:;' className='tx-primary' onClick={(e) => gotoregister()}>Change</a></p>
                            {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                                {errormsg}
                            </Alert> : ''}
                            {successmsg ? <Alert key={'success'} className='success' variant='success'>
                                {successmsg}
                            </Alert> : ''}
                            <form onSubmit={handleSubmit(onSubmitverifyotp)}>
                                <div className='formgroup mb-20 otp-input' >
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </div>
                                <div className='formgroup mb-20'>
                                    {resendTimer === 0 ? (
                                        <p className=''>
                                            Did not receive OTP? <a href='javascript:;' className='tx-primary' onClick={(e) => resendOTP()}>Resend OTP</a>
                                        </p>
                                    ) : (
                                        <p className=''>Resend code in {resendTimer} sec</p>
                                    )}
                                </div>
                                <div className='formgroup mb-20'>
                                    <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                    ) : (
                                        "Continue"
                                    )}</button>
                                </div>
                            </form>
                            <div className='formgroup mb-20' onClick={() => { gotologin() }}>
                                <p className='text-center'>Already have an account? Login.</p>
                            </div>
                        </>
                    }
                    {/* Verify Otp End */}
                    {step == 4 && <>
                        <h4>Create Account</h4>
                        <p>Please do the registration to continue.</p>
                        {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                            {errormsg}
                        </Alert> : ''}
                        {successmsg ? <Alert key={'success'} className='success' variant='success'>
                            {successmsg}
                        </Alert> : ''}
                        <form onSubmit={handleSubmit(continueregister)}>
                            <div className='formgroup mb-20'>
                                <label><div>Full Name<span className='tx-red '>*</span></div></label>
                                <input type='text' {...register("user_registname", { required: true, })} name="user_registname" class="form-control"></input>
                                {errors.user_registname && errors.user_registname.type === "required" && (
                                    <small className="text-danger">User Name is required.</small>
                                )}
                            </div>
                            <div className='formgroup mb-20'>
                                <label><div>DOB<span className='tx-red '>*</span></div></label>
                                <input type='date' name="user_regisdob"  {...register("user_regisdob", { required: true, })} ></input>
                                {errors.user_regisdob && errors.user_regisdob.type === "required" && (
                                    <small className="text-danger">Dob is required.</small>)}
                            </div>
                            <div className='formgroup mb-20'>
                                <label><div>Mobile Number</div></label>
                                <input type='number' name="user_registmobileno" {...register("user_registmobileno")} ></input>
                            </div>
                            <div className='formgroup mb-20'>
                                <label><div>Address</div></label>
                                <input type='text' name="user_regisaddress"   {...register("user_regisaddress")} ></input>
                            </div>


                            <div className='formgroup mb-20'>
                                <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                ) : (
                                    "Continue"
                                )}</button>
                            </div>
                        </form>

                        <div className='formgroup mb-20' onClick={() => { gotologin() }}>
                            <p className='text-center'>Already have an account? Login.</p>
                        </div>
                    </>}
                    {/* {Regsitartion fiels End} */}

                    {step == 5 && <>

                        {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                            {errormsg}
                        </Alert> : ''}
                        {successmsg ? <Alert key={'success'} className='success' variant='success'>
                            {successmsg}
                        </Alert> : ''}
                        <form onSubmit={handleSubmit(onSubmitregister)}>
                            <div className='formgroup mb-20'>
                                <label><div>Password<span className='tx-red '>*</span></div></label>
                                <input type='password' name="user_registepassword"  {...register("user_registepassword", { required: true, })} class="form-control"></input>
                                {errors.user_registepassword && errors.user_registepassword.type === "required" && (
                                    <small className="text-danger">Password is required.</small>
                                )}
                            </div>
                            <div className='formgroup mb-20'>
                                <label><div>Confirm Password<span className='tx-red '>*</span></div></label>
                                <input type='password' name="user_resgisCpassword"  {...register("user_resgisCpassword", { required: true, })} class="form-control"></input>
                                {errors.user_resgisCpassword && errors.user_resgisCpassword.type === "required" && (
                                    <small className="text-danger">Confirm Password is required.</small>
                                )}
                            </div>

                            <div className='formgroup mb-20'>
                                <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                ) : (
                                    "Register"
                                )}</button>
                            </div>
                        </form>

                        <div className='formgroup mb-20' onClick={() => { gotologin() }}>
                            <p className='text-center'>Already have an account? Login.</p>
                        </div>
                    </>}

                    {step == 6 && <>
                        <h4>Forget Password</h4>
                        {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                            {errormsg}
                        </Alert> : ''}
                        {successmsg ? <Alert key={'success'} className='success' variant='success'>
                            {successmsg}
                        </Alert> : ''}
                        <form onSubmit={handleSubmit(onforgetPassord)}>
                            <div className='formgroup mb-20'>
                                <label><div>Email<span className='tx-red '>*</span></div></label>
                                <input type='email'  {...register("forget_user_email", { required: true, })} name="forget_user_email" class="form-control"></input>
                                {errors.forget_user_email && errors.forget_user_email.type === "required" && (
                                    <small className="text-danger">Email is required.</small>
                                )}
                            </div>
                            <div className='formgroup mb-20'>
                                <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                ) : (
                                    "Continue"
                                )}</button>
                            </div>
                        </form>


                    </>}

                    {
                        step == 7 && <>
                            <h4>Verify Otp</h4>
                            <p>We have sent a verification code to {userEmail} <a href='javascript:;' className='tx-primary' onClick={(e) => gotoregister('forget')}>Change</a></p>
                            {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                                {errormsg}
                            </Alert> : ''}
                            {successmsg ? <Alert key={'success'} className='success' variant='success'>
                                {successmsg}
                            </Alert> : ''}
                            <form onSubmit={handleSubmit(onSubmitforgetOtp)}>
                                <div className='formgroup mb-20 otp-input' >
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </div>
                                <div className='formgroup mb-20'>
                                    {resendTimer === 0 ? (
                                        <p className=''>
                                            Did not receive OTP? <a href='javascript:;' className='tx-primary' onClick={(e) => resendforgetOTP()}>Resend OTP</a>
                                        </p>
                                    ) : (
                                        <p className=''>Resend code in {resendTimer} sec</p>
                                    )}
                                </div>
                                <div className='formgroup mb-20'>
                                    <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                    ) : (
                                        "Continue"
                                    )}</button>
                                </div>
                            </form>

                        </>
                    }

                    {step == 8 && <>
                        {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
                            {errormsg}
                        </Alert> : ''}
                        {successmsg ? <Alert key={'success'} className='success' variant='success'>
                            {successmsg}
                        </Alert> : ''}
                        <form onSubmit={handleSubmit(onChangePassword)}>
                            <div className='formgroup mb-20'>
                                <label><div>Password<span className='tx-red '>*</span></div></label>
                                <input type='password' name="user_changepassword"  {...register("user_changepassword", { required: true, })} class="form-control"></input>
                                {errors.user_changepassword && errors.user_changepassword.type === "required" && (
                                    <small className="text-danger">Password is required.</small>
                                )}
                            </div>
                            <div className='formgroup mb-20'>
                                <label><div>Confirm Password<span className='tx-red '>*</span></div></label>
                                <input type='password' name="user_changeCpassword"  {...register("user_changeCpassword", { required: true, })} class="form-control"></input>
                                {errors.user_changeCpassword && errors.user_changeCpassword.type === "required" && (
                                    <small className="text-danger">Confirm Password is required.</small>
                                )}
                            </div>

                            <div className='formgroup mb-20'>
                                <button className='btn btn-primary btn-full btn-lg' disabled={showloader}>{showloader ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                ) : (
                                    "Change"
                                )}</button>
                            </div>
                        </form>
                    </>}
                </div>
            </Modal>
        </>
    );
}

export default Loginmodal;